import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkName } from 'types/network';

type State = {
  prev: string;
  netWork: NetworkName | null;
};

const initialState: State = {
  prev: '/',
  netWork: null,
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPrevPage: (state, action: PayloadAction<string>) => {
      if (/^\/[0-9a-zA-Z/]{0,}/.test(action.payload)) {
        state.prev = action.payload;
      }
    },
    resetPrevPage: (state) => {
      state.prev = initialState.prev;
    },
    setPageNetwork: (state, action: PayloadAction<NetworkName | null>) => {
      state.netWork = action.payload;
    },
  },
});

export const { setPrevPage, resetPrevPage, setPageNetwork } = pageSlice.actions;

export default pageSlice.reducer;
