/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-template */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import errorCodeList from 'data/responseStatus.json';
import sendSlackMessage from 'lib/slack';

import { ResponseError } from './error';

const baseURL = process.env.NEXT_PUBLIC_API_HOST;

export const setAxiosResponseInterceptorError = (
  status: number,
  message: string,
  apiUrl?: string,
  method?: string,
  data?: string,
) => {
  const responseError = new ResponseError();
  const errorCode = errorCodeList.find((item) => item.status === status);

  if (errorCode) {
    responseError.status = errorCode.status;
    responseError.message = errorCode.message;
  } else {
    responseError.status = status;
    responseError.message = message;
  }

  if (
    process.env.NEXT_PUBLIC_ENV === 'production' &&
    responseError &&
    apiUrl &&
    method
  ) {
    sendSlackMessage({
      title: 'Backend API Error😱',
      subTitle: `API: [${method.toUpperCase()}] ${apiUrl}`,
      message: data ? '```' + data + '```' : '',
      message2: `${responseError.status} - ${responseError.message}`,
      type: 'api',
    });
  }

  throw responseError;
};

const setAxiosResponseInterceptorResponse = (response: AxiosResponse) => {
  const { status, data } = response;

  const errorCode = errorCodeList.find((item) => item.status === status);
  const message = errorCode ? errorCode.message : 'unknown error';

  return {
    status,
    message,
    data: data?.data || null,
  };
};

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('PDMP_AUTH_TOKEN');
  const network = localStorage.getItem('PDMP_NETWORK');
  const nextConfig: AxiosRequestConfig = config;

  if (token) {
    nextConfig.headers = {
      ...nextConfig.headers,
      token,
    };
  }

  if (network && !nextConfig.headers?.network) {
    nextConfig.headers = {
      ...nextConfig.headers,
      network,
    };
  }

  return nextConfig;
});

api.interceptors.response.use(
  (response) => setAxiosResponseInterceptorResponse(response),
  (error) => {
    const { baseURL, method, url, data } = error.response.config;
    const apiUrl = `${baseURL}${url}`;

    setAxiosResponseInterceptorError(
      error.response.status as number,
      error.response.data.message as string,
      apiUrl,
      method,
      data,
    );
  },
);

export default api;
