import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { postMerge } from 'api/merge';
import { MergeSuccessData, Payload } from 'types/merge';

type State = {
  mergeStatus: '' | 'fail' | 'loading' | 'success';
  mergeResult: MergeSuccessData | null;
  isMergeOpen: boolean;
};

const initialState: State = {
  mergeStatus: '',
  mergeResult: null,
  isMergeOpen: false,
};

export const fetchMerge = createAsyncThunk<MergeSuccessData, Payload>(
  'merge/fetchMerge',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postMerge(payload);

      if (response.status !== 200) {
        throw new Error('Failed to merge');
      }

      const tokenData =
        'PlayzV2' in response.data.tokens
          ? response.data.tokens.PlayzV2
          : response.data.tokens.Dragon;

      return {
        image: tokenData[0].image,
        name: tokenData[0].name,
        txHashVRF: response.data.txHashVRF,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mergeSlice = createSlice({
  name: 'merge',
  initialState,
  reducers: {
    setMergeStatus: (state, action) => {
      state.mergeStatus = action.payload;
    },
    setMergeResult: (state, action) => {
      state.mergeResult = action.payload;
    },
    setIsMergeOpen: (state, action) => {
      state.isMergeOpen = action.payload;
    },
    resetState: (state) => {
      state.mergeStatus = '';
      state.mergeResult = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMerge.pending, (state) => {
      state.mergeStatus = 'loading';
      state.mergeResult = null;
    });
    builder.addCase(fetchMerge.fulfilled, (state, action) => {
      state.mergeStatus = 'success';
      state.mergeResult = action.payload;
    });
    builder.addCase(fetchMerge.rejected, (state) => {
      state.mergeStatus = 'fail';
      state.mergeResult = null;
    });
  },
});

export const { setMergeStatus, setMergeResult, setIsMergeOpen, resetState } =
  mergeSlice.actions;

export default mergeSlice.reducer;
