/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Web3Provider } from '@ethersproject/providers';
import { RequestProvider } from 'caver-js';

import {
  PlaydappMarketplaceConn as EthConnector,
  Network as EthNetwork,
  EventType as EthEventType,
  orderFromJSON as EthOrderFromJSON,
} from '@playdapp/marketplace-js';
import {
  PlaydappMarketplaceConn as PolygonConnector,
  Network as PolygonNetwork,
  EventType as PolygonEventType,
  orderFromJSON as PolygonOrderFromJSON,
} from '@playdapp/marketplace-matic-js';

import {
  PlaydappMarketplaceConn as KlaytnConnector,
  Network as KlaytnNetwork,
  EventType as KlaytnEventType,
  orderFromJSON as KlaytnOrderFromJSON,
} from '@playdapp/marketplace-klaytn-js';

import { Order as EthOrder } from '@playdapp/marketplace-js/lib/types';
import {
  Order as PolygonOrder,
  OrderSide,
  OrderQuery,
} from '@playdapp/marketplace-matic-js/lib/types';
import { Order as KlaytnOrder } from '@playdapp/marketplace-klaytn-js/lib/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from 'api';
import { getBidOrder } from 'api/item';
import { RootState } from 'store';
import { activeChainId } from 'lib/network';
import { getTokenAddress } from 'lib/util';
import type { TxStatus } from 'types/transaction';
import type { NetworkName } from 'types/network';

type State = {
  pdmpConnector: EthConnector | KlaytnConnector | PolygonConnector | null;
  isConnectorLoading: boolean;
  txStatus: TxStatus | null;
  transactionHash: string | null;
  currentRequestId?: string;
};

type SetConnectorParams = {
  network: NetworkName;
  library?: Web3Provider;
  orderId?: string;
  // TODO: remove this
  host?: string;
  apiKey?: string;
};

type TransferAssetParams = {
  from: string;
  to: string;
  tokenId: string;
  tokenAddress: string;
};

type ManageSellRequestParams = {
  network: NetworkName;
  contractAddress: string;
  tokenId: string;
  accountAddress: string;
  startAmount: number;
  currency: string;
  endAmount: number;
  expirationTime: number;
  waitForHighestBid: boolean;
};

type ManageBundleSellRequestParams = {
  network: NetworkName;
  accountAddress: string;
  currency: string;
  price: string;
  bundleName: string;
  assets: {
    tokenAddress: string;
    tokenId: string;
  }[];
};

type CreateBuyOrderParams = {
  network: NetworkName;
  tokenId: string;
  contractAddress: string;
  accountAddress: string;
  expirationTime: number;
  startAmount: number;
  currency: string;
};

type ManageBuyRequestParams = {
  accountAddress: string;
  tokenId: string;
  tokenAddress: string;
  orderId?: number;
  bundled: boolean;
};

type ManageAcceptRequestParams = {
  accountAddress: string;
  tokenId: string;
  assetId: string;
  orderId: string;
};

type FulfillAuctionParams = {
  accountAddress: string;
  tokenId: string;
  assetId: string;
  orderId: string;
};

type ManageBuyRequestReject = {
  state: 'fullfillOrder' | 'order';
};

type WrapEthRequestParams = {
  amountInEth: number;
  accountAddress: string;
  currency: string;
};

export const setConnector = createAsyncThunk<
  EthConnector | KlaytnConnector | PolygonConnector,
  SetConnectorParams,
  { state: RootState }
>(
  'marketplace/setConnector',
  (
    { network, library, host, apiKey }: SetConnectorParams,
    { getState, dispatch, rejectWithValue },
  ) => {
    let provider;
    if (library instanceof Web3Provider) {
      const { provider: nextProvider } = getState().wallet.library ?? library;
      provider = nextProvider;
    }

    if (!provider) {
      return rejectWithValue(
        'Failed to init pdmp connector: web3 provider is not found',
      );
    }

    const networkNameList = {
      1: EthNetwork.Main,
      11155111: 'sepolia' as EthNetwork.Goerli,
      137: PolygonNetwork.Main,
      80002: 'amoy' as PolygonNetwork.Mumbai,
      1001: KlaytnNetwork.Baobab,
      8217: KlaytnNetwork.Main,
    };

    const EventType =
      network === 'ethereum'
        ? EthEventType
        : network === 'klaytn'
        ? KlaytnEventType
        : PolygonEventType;

    const actionSetTxStatus = 'marketplace/setTxStatus';
    const actionSetTxStatusWithHash = 'marketplace/setTxStatusWithHash';

    const token = apiKey ?? localStorage.getItem('PDMP_AUTH_TOKEN');

    const apiBaseUrl = `${host ?? process.env.NEXT_PUBLIC_API_HOST}/${network}`;

    if (!token) {
      return rejectWithValue(
        'Failed to init pdmp connector: user token is not found',
      );
    }

    try {
      const connector: EthConnector | KlaytnConnector | PolygonConnector =
        network === 'ethereum'
          ? /* @ts-ignore */
            new EthConnector(provider, {
              networkName: networkNameList[activeChainId.ethereum],
              apiBaseUrl,
              apiKey: token,
            })
          : network === 'klaytn'
          ? /* @ts-ignore */
            new KlaytnConnector(provider as RequestProvider, {
              networkName: networkNameList[activeChainId.klaytn],
              apiBaseUrl,
              apiKey: token,
            })
          : /* @ts-ignore */
            new PolygonConnector(provider, {
              networkName: networkNameList[activeChainId.polygon],
              apiBaseUrl,
              apiKey: token,
            });

      connector.addListener(
        EventType.TransactionCreated,
        ({ transactionHash, event }) => {
          console.info({ transactionHash, event });
          dispatch({
            type: actionSetTxStatusWithHash,
            payload: {
              status: transactionHash
                ? 'SET_PENDING_TRANSACTION_HASH'
                : 'TX_DENIED',
              transactionHash,
            },
          });
        },
      );

      connector.addListener(
        EventType.TransactionConfirmed,
        ({ transactionHash, event }) => {
          console.info({ transactionHash, event });
          // Only reset your exchange UI if we're finishing an order fulfillment or cancellation
          if (
            event === EventType.MatchOrders ||
            event === EventType.CancelOrder
          ) {
            dispatch({
              type: actionSetTxStatus,
              payload: { status: 'RESET_EXCHANGE', transactionHash },
            });
          }
        },
      );

      connector.addListener(
        EventType.TransactionDenied,
        ({ transactionHash, event }) => {
          console.info({ transactionHash, event });
          dispatch({
            type: actionSetTxStatus,
            payload: 'TX_DENIED',
          });
        },
      );

      connector.addListener(
        EventType.TransactionFailed,
        ({ transactionHash, event }) => {
          console.info({ transactionHash, event });
          dispatch({
            type: actionSetTxStatus,
            payload: 'TX_FAILED',
          });
        },
      );

      connector.addListener(
        EventType.InitializeAccount,
        ({ accountAddress }) => {
          console.info({ accountAddress });
          dispatch({
            type: actionSetTxStatus,
            payload: 'INITIALIZE_PROXY',
          });
        },
      );

      connector.addListener(EventType.WrapEth, ({ accountAddress, amount }) => {
        console.info({ accountAddress, amount });
        dispatch({
          type: actionSetTxStatus,
          payload: 'WRAP_ETH',
        });
      });

      connector.addListener(
        EventType.UnwrapWeth,
        ({ accountAddress, amount }) => {
          console.info({ accountAddress, amount });
          dispatch({
            type: actionSetTxStatus,
            payload: 'UNWRAP_WETH',
          });
        },
      );

      connector.addListener(
        EventType.ApproveCurrency,
        ({ accountAddress, contractAddress, proxyAddress }) => {
          console.info({ accountAddress, contractAddress, proxyAddress });
          dispatch({
            type: actionSetTxStatus,
            payload: 'APPROVE_CURRENCY',
          });
        },
      );

      connector.addListener(EventType.ApproveAllAssets, (data) => {
        console.info({
          data,
          accountAddress: data.accountAddress,
          proxyAddress: data.proxyAddress,
        });
        dispatch({
          type: actionSetTxStatus,
          payload: 'APPROVE_ALL_ASSETS',
        });
      });

      connector.addListener(
        EventType.ApproveAsset,
        ({ accountAddress, proxyAddress }) => {
          console.info({ accountAddress, proxyAddress });
          dispatch({
            type: actionSetTxStatus,
            payload: 'APPROVE_ASSET',
          });
        },
      );

      connector.addListener(
        EventType.CreateOrder,
        ({ order, accountAddress }) => {
          console.info('test', connector, { order, accountAddress });
          dispatch({
            type: actionSetTxStatus,
            payload: 'CREATE_ORDER',
          });
        },
      );

      connector.addListener(EventType.ApproveOrder, (returnedVal) => {
        console.info(returnedVal);
        dispatch({
          type: actionSetTxStatus,
          payload: 'APPROVE_ORDER',
        });
      });

      connector.addListener(
        EventType.OrderDenied,
        ({ order, accountAddress }) => {
          console.info({ order, accountAddress });
          dispatch({
            type: actionSetTxStatus,
            payload: 'TX_DENIED',
          });
        },
      );

      connector.addListener(
        EventType.MatchOrders,
        ({ buy, sell, accountAddress }) => {
          console.info({ buy, sell, accountAddress });
          dispatch({
            type: actionSetTxStatus,
            payload: 'FULFILL_ORDER',
          });
        },
      );

      connector.addListener(EventType.TransferAll, (data) => {
        console.info(data);
        dispatch({
          type: actionSetTxStatus,
          payload: 'TRANSFER_ALL',
        });
      });

      connector.addListener(EventType.TransferOne, (data) => {
        console.info(data);
        dispatch({
          type: actionSetTxStatus,
          payload: 'TRANSFER_ONE',
        });
      });

      // This will be triggered if user rejects to sign.
      connector.addListener(
        EventType.CancelOrder,
        ({ order, accountAddress, event }) => {
          console.info({ order, accountAddress, event });
          dispatch({ type: actionSetTxStatus, payload: 'TX_DENIED' });
        },
      );

      connector.addListener(EventType.LiquidateAssets, (val) => {
        console.info(val);
        dispatch({ type: actionSetTxStatus, payload: 'LIQUIDATE_ASSETS' });
      });

      return connector;
    } catch (e) {
      console.error(e);
      return rejectWithValue(e);
    }
  },
);

export const transferAsset = createAsyncThunk<
  string | null,
  TransferAssetParams,
  { state: RootState }
>(
  'marketplace/transferAsset',
  async (
    { from, to, tokenId, tokenAddress }: TransferAssetParams,
    { getState, rejectWithValue },
  ) => {
    try {
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return null;
      }

      const param = {
        assets: [
          {
            tokenId,
            tokenAddress,
          },
        ],
        fromAddress: from.toLowerCase(),
        toAddress: to.toLowerCase(),
      };
      const transferResult = await pdmpConnector.transferAll(param);
      return transferResult;
    } catch (err) {
      console.error(err);
      rejectWithValue(err);
      return null;
    }
  },
);

export const manageSellRequest = createAsyncThunk<
  EthOrder | KlaytnOrder | PolygonOrder | null,
  ManageSellRequestParams,
  { state: RootState }
>(
  'marketplace/manageSellRequest',
  async (
    {
      network,
      contractAddress,
      tokenId,
      accountAddress,
      startAmount,
      currency,
      endAmount,
      expirationTime,
      waitForHighestBid,
    }: ManageSellRequestParams,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return null;
      }

      const paymentTokenAddress = getTokenAddress(network, currency);

      if (!paymentTokenAddress) {
        throw new Error('An error occurred while getting token address.');
      }

      const param = {
        asset: {
          tokenAddress: contractAddress,
          tokenId: tokenId.toString(),
        },
        accountAddress,
        startAmount,
        endAmount: expirationTime ? endAmount : startAmount,
        expirationTime,
        paymentTokenAddress,
        waitForHighestBid,
      };

      const result = await pdmpConnector.createSellOrder(param);

      if (result.hash) {
        dispatch({
          type: 'marketplace/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const manageBundleSellRequest = createAsyncThunk<
  EthOrder | KlaytnOrder | PolygonOrder | null,
  ManageBundleSellRequestParams,
  { state: RootState }
>(
  'marketplace/manageSellRequest',
  async (
    {
      network,
      accountAddress,
      currency,
      price,
      bundleName,
      assets,
    }: ManageBundleSellRequestParams,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return null;
      }

      const paymentTokenAddress = getTokenAddress(
        network,
        currency.toLowerCase(),
      );

      if (!paymentTokenAddress) {
        throw new Error('An error occurred while getting token address.');
      }

      const param = {
        bundleName,
        assets,
        accountAddress,
        startAmount: Number(price),
        paymentTokenAddress,
      };

      const result = await pdmpConnector.createBundleSellOrder(param);

      if (result.hash) {
        dispatch({
          type: 'marketplace/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const wrapEth = createAsyncThunk<
  void,
  WrapEthRequestParams,
  { state: RootState }
>(
  'marketplace/wrapEth',
  async (
    { accountAddress, amountInEth, currency }: WrapEthRequestParams,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      // eth의 잔액 조회
      const { balance } = getState().wallet;
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return;
      }

      if (!balance) return;

      const balanceEth = balance.eth;
      const balanceKlay = balance.klay;

      if (balanceEth <= 0 && currency === 'ETH') {
        throw new Error('An error occurred while getting eth balance');
      }
      if (balanceEth <= amountInEth && currency === 'ETH') {
        throw new Error('An error occurred while getting eth balance');
      }

      if (balanceKlay <= 0 && currency === 'KLAY') {
        throw new Error('An error occurred while getting klay balance');
      }
      if (balanceKlay <= amountInEth && currency === 'KLAY') {
        throw new Error('An error occurred while getting klay balance');
      }
      const param = {
        accountAddress,
        amountInEth,
      };
      await pdmpConnector.wrapEth(param);
      dispatch({
        type: 'marketplace/successTxStatus',
      });
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const createBuyOrder = createAsyncThunk<
  EthOrder | KlaytnOrder | PolygonOrder | null,
  CreateBuyOrderParams,
  { state: RootState }
>(
  'marketplace/createBuyOrder',
  async (
    {
      network,
      tokenId,
      contractAddress,
      accountAddress,
      expirationTime,
      startAmount,
      currency,
    }: CreateBuyOrderParams,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return null;
      }

      const paymentTokenAddress = getTokenAddress(network, currency);

      if (!paymentTokenAddress && network !== 'klaytn') {
        throw new Error('An error occurred while getting token address.');
      }

      const param = {
        asset: {
          tokenAddress: contractAddress,
          tokenId: tokenId.toString(),
        },
        accountAddress,
        expirationTime,
        startAmount,
        paymentTokenAddress,
      };

      const result = await pdmpConnector.createBuyOrder(param);

      if (result.hash) {
        dispatch({
          type: 'marketplace/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const manageBuyRequest = createAsyncThunk<
  void,
  ManageBuyRequestParams,
  {
    state: RootState;
    rejectValue: ManageBuyRequestReject;
  }
>(
  'marketplace/manageBuyRequest',
  async (
    {
      accountAddress,
      tokenId,
      tokenAddress,
      bundled = false,
    }: ManageBuyRequestParams,
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return;
      }

      const query: OrderQuery = {
        token_id: tokenId,
        asset_contract_address: tokenAddress,
        side: OrderSide.Sell,
        limit: 1,
        bundled,
      };
      const order = await pdmpConnector.api.getOrder(query);

      try {
        await pdmpConnector.fulfillOrder({ order, accountAddress });
      } catch (err) {
        return rejectWithValue({ state: 'fullfillOrder' });
      }

      dispatch({
        type: 'marketplace/successTxStatus',
      });
    } catch (err) {
      return rejectWithValue({ state: 'order' });
    }
  },
);

export const manageAcceptRequest = createAsyncThunk<
  void,
  ManageAcceptRequestParams,
  {
    state: RootState;
  }
>(
  'marketplace/manageAcceptRequest',
  async (
    { accountAddress, tokenId, assetId, orderId }: ManageAcceptRequestParams,
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return;
      }

      // TODO: 테스트 끝나면 롤백
      const orderResult = await api.get(
        `pdx/v1/orders/buy?assetId=${assetId}&tokenId=${tokenId}&orderId=${orderId}`,
        { baseURL: pdmpConnector.api.apiBaseUrl },
      );

      const {
        data: { orders },
      } = orderResult;

      const orderFromJson =
        pdmpConnector instanceof PolygonConnector
          ? PolygonOrderFromJSON
          : pdmpConnector instanceof KlaytnConnector
          ? KlaytnOrderFromJSON
          : EthOrderFromJSON;

      await pdmpConnector.fulfillOrder({
        order: orderFromJson(orders[0]),
        accountAddress,
      });

      dispatch({
        type: 'marketplace/successTxStatus',
      });
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const fulfillAuction = createAsyncThunk<
  void,
  FulfillAuctionParams,
  {
    state: RootState;
  }
>(
  'marketplace/fulfillAuction',
  async (
    { accountAddress, tokenId, assetId, orderId }: ManageAcceptRequestParams,
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const { pdmpConnector } = getState().marketplace;

      if (!pdmpConnector) {
        return;
      }

      const { data: orders } = await getBidOrder({ assetId, tokenId, orderId });

      const orderFromJson =
        pdmpConnector instanceof PolygonConnector
          ? PolygonOrderFromJSON
          : EthOrderFromJSON;

      await pdmpConnector.fulfillOrder({
        order: orderFromJson(orders[0]),
        accountAddress,
      });

      dispatch({
        type: 'marketplace/successTxStatus',
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const initialState: State = {
  pdmpConnector: null,
  isConnectorLoading: false,
  txStatus: null,
  transactionHash: null,
  currentRequestId: undefined,
};

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    setTxStatus: (state, action) => {
      state.txStatus = action.payload;
    },
    setTxStatusWithHash: (state, action) => {
      const { status, transactionHash } = action.payload;
      state.txStatus = status;
      state.transactionHash = transactionHash;
    },
    clearTxStatus: (state) => {
      state.txStatus = null;
    },
    successTxStatus: (state) => {
      state.txStatus = 'TX_SUCCESS';
    },
    resetConnector: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(setConnector.pending, (state, action) => {
      state.isConnectorLoading = true;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(setConnector.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.pdmpConnector = action.payload;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(setConnector.rejected, (state) => {
      state.isConnectorLoading = false;
      state.currentRequestId = undefined;
    });
    builder.addCase(transferAsset.fulfilled, (state) => {
      state.txStatus = null;
      state.currentRequestId = undefined;
    });
    builder.addCase(transferAsset.rejected, (state) => {
      state.txStatus = null;
      state.currentRequestId = undefined;
    });
    builder.addCase(manageSellRequest.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(createBuyOrder.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(manageBuyRequest.rejected, (state, action) => {
      if (
        state.txStatus !== 'TX_DENIED' &&
        action.payload?.state === 'fullfillOrder'
      ) {
        state.txStatus = 'TX_DENIED';
        state.currentRequestId = undefined;
        return;
      }
      if (state.txStatus !== 'TX_DENIED' && action.payload?.state === 'order') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(manageAcceptRequest.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fulfillAuction.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(wrapEth.fulfilled, (state) => {
      state.txStatus = null;
      state.currentRequestId = undefined;
    });
    builder.addCase(wrapEth.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { resetConnector, clearTxStatus } = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
