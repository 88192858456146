import qs from 'qs';

import api from 'api';
import type ResponseResult from 'types/response';
import type {
  UserAuthRequest,
  UserAuthResponseData,
  UserConsentRequest,
  UserRequest,
  UserResponseData,
  UserEditRequest,
  UserEditRequestData,
  UserOfferRequest,
  UserOfferResponseData,
  UserActivityRequest,
  UserActivityResponseData,
  UserMergeHistoryRequest,
  UserMergeHistoryResponseData,
  UserFavoriteRequest,
  UserFavoriteResponseData,
} from 'types/user';
import type { EmailAuthResponse } from 'types/email';

export const auth = async ({
  account,
  provider,
  sign,
  network,
  version,
}: UserAuthRequest): Promise<ResponseResult<UserAuthResponseData>> =>
  api.post(`/auth?network=${network}`, { account, provider, sign, version });

export const getUser = async ({
  account,
}: UserRequest): Promise<ResponseResult<UserResponseData>> => {
  const queryString = qs.stringify({ account });
  return api.get(`/user?${queryString}`);
};

export const emailAuth = (
  token: string,
): Promise<ResponseResult<EmailAuthResponse>> =>
  api.put('/auth/email', {
    token,
  });

export const agreeConsent = ({
  termsConsent,
  marketingConsent,
}: UserConsentRequest): Promise<ResponseResult<boolean>> =>
  api.patch('/user/consent', {
    termsConsent,
    marketingConsent,
  });

export const editNickName = ({
  account,
  alias,
}: UserEditRequest): Promise<ResponseResult<UserEditRequestData>> =>
  api.patch('/user/alias', {
    account,
    alias,
  });

export const getMyOffers = ({
  network,
  type,
  offset,
  count,
}: UserOfferRequest): Promise<ResponseResult<UserOfferResponseData>> =>
  api.get(`/user/offers/${type}/${offset}/${count}?network=${network}`);

export const getMyActivity = ({
  account,
  type,
  page,
}: UserActivityRequest): Promise<ResponseResult<UserActivityResponseData>> =>
  api.get(`/user/activity?account=${account}&type=${type}&page=${page}`);

export const getMyMergeHistory = ({
  count,
  offset,
}: UserMergeHistoryRequest): Promise<
  ResponseResult<UserMergeHistoryResponseData>
> => api.get(`/merge/history/${count}/${offset}`);

export const getMyFavorite = ({
  email,
  page,
  filter,
  isBundle,
}: UserFavoriteRequest): Promise<ResponseResult<UserFavoriteResponseData>> =>
  api.get(
    `/user/favorite?email=${email}&page=${page}&filter=${filter}&isBundle=${isBundle}`,
  );
