import api from 'api';

import ResponseResult from 'types/response';
import { CollectionResponseData } from 'types/collections';

export const getCollections = (): Promise<
  ResponseResult<CollectionResponseData[]>
> => api.get('/collection');

export default getCollections;
