import { useEffect, useCallback } from 'react';
import { useMedia } from 'react-use';
import { breakpoints } from '@playdapp/ui';
import { useToast } from '@chakra-ui/react';

import type { MessageType } from 'types/event';

import { useAppSelector } from 'store/hooks';
import useOpenControl from 'hooks/useOpenControl';
import { getBreakpointQuery } from 'lib/util';
import { TOAST_ID, MERGE_ERROR_TEXT_LIST } from 'data/merge';

import MergeErrorModal from '@/components/Modal/MergeErrorModal';
import MergeSuccessModal from '@/components/Modal/MergeSuccessModal';
import Toast from '@/components/Toast';

const MergeStatus = () => {
  const isMdUpScreen = useMedia(getBreakpointQuery(breakpoints.up('md')));

  const { mergeStatus, mergeResult, isMergeOpen } = useAppSelector(
    ({ merge }) => merge,
  );

  const toast = useToast({
    position: 'top',
    duration: 600000,
    isClosable: true,
    containerStyle: {
      maxWidth: '900px',
      width: 'auto',
      marginTop: '5.5rem',
    },
  });

  const [isMergeErrorModalOpen, handleMergeErrorModalOpen] = useOpenControl();
  const [isMergeSuccessModalOpen, handleMergeSuccessModalOpen] =
    useOpenControl();

  const handleUpdateToast = useCallback(
    (children: React.ReactNode, type: MessageType, allowClose: boolean) => {
      if (!toast.isActive(TOAST_ID)) {
        toast({
          id: TOAST_ID,
          render: ({ onClose }) => (
            <Toast type={type} allowClose={allowClose} onClose={onClose}>
              {children}
            </Toast>
          ),
        });
      }
    },
    [toast],
  );

  useEffect(() => {
    if ((isMdUpScreen || isMergeOpen) && mergeStatus === 'loading') {
      handleUpdateToast('Merging...', 'loading', false);
    } else {
      toast.closeAll();
    }

    if (mergeStatus === 'success') {
      handleMergeSuccessModalOpen(true);
    }

    if (mergeStatus === 'fail') {
      handleMergeErrorModalOpen(true);
    }
  }, [
    mergeStatus,
    isMdUpScreen,
    toast,
    isMergeOpen,
    handleUpdateToast,
    handleMergeSuccessModalOpen,
    handleMergeErrorModalOpen,
  ]);

  return (
    <>
      <MergeErrorModal
        isOpen={isMergeErrorModalOpen}
        textList={MERGE_ERROR_TEXT_LIST}
        handleOpen={handleMergeErrorModalOpen}
      />

      <MergeSuccessModal
        isOpen={isMergeSuccessModalOpen}
        mergeSuccessData={mergeResult}
        handleOpen={handleMergeSuccessModalOpen}
      />
    </>
  );
};

export default MergeStatus;
