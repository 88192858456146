import { useCallback, useEffect, useState } from 'react';

type ReturnValue = [boolean, (openstate: boolean) => void, () => void];

const useOpenControl = (): ReturnValue => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback((openState: boolean) => {
    setIsOpen(openState);
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (isOpen && body) {
      body.style.overflowY = 'hidden';
    }
    if (!isOpen && body) {
      body.style.removeProperty('overflow-y');
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, handleOpen, handleClose];
};

export default useOpenControl;
