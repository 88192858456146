import axios from 'axios';

const host = {
  development:
    'https://mcl1uck61h.execute-api.ap-northeast-2.amazonaws.com/test/v4/game/dozer/country',
  production:
    'https://qtsaj4j9bc.execute-api.ap-southeast-1.amazonaws.com/prod/v4/game/dozer/country',
};

export const getCountry = async () => {
  const { data } = await axios.get(
    host[
      process.env.NEXT_PUBLIC_ENV === 'production'
        ? 'production'
        : 'development'
    ],
  );

  if (data.result) {
    return data.data;
  }
  throw new Error('Service not working');
};
export default null;
