import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import { formatEther } from '@ethersproject/units';
import BigNumber from 'bignumber.js';

import { tokenAddress } from 'data/crypto-token';
import erc20Interface from 'data/contracts/ERC20.json';

export default async function getBalance(
  account: string,
  library: Web3Provider,
) {
  try {
    const balance = await library.getBalance(account);
    return parseFloat(formatEther(balance));
  } catch (e) {
    return -1;
  }
}

export async function getTokenBalance(
  account: string,
  tokenName: 'weth' | 'wklay',
  chainId: number,
  library: Web3Provider,
) {
  try {
    const contract = new Contract(
      tokenAddress[tokenName][chainId],
      erc20Interface,
      library.getSigner(),
    );

    const tokenBalance = await contract.balanceOf(account);
    return new BigNumber(formatEther(tokenBalance)).toNumber();
  } catch (e) {
    return -1;
  }
}
