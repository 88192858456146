import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';

import Image from '@/components/Image';

type Props = {
  to?: string;
  isShowIcon?: boolean;
};

const CustomLink = styled.a`
  display: flex;
  align-items: center;
`;

export const Redirect: React.FC<Props> = ({
  to,
  children,
  isShowIcon = false,
  ...props
}) => {
  if (!to) {
    return (
      <a>
        {children}
        {isShowIcon && (
          <Image
            src="/assets/images/icons/link.svg"
            width="18px"
            height="18px"
            alt="link-icon"
          />
        )}
      </a>
    );
  }

  if (typeof to === 'string' && /^https?:\/\//.test(to)) {
    return (
      <CustomLink
        href={to}
        rel="noopener noreferrer"
        target="_blank"
        {...props}
      >
        {children}
        {isShowIcon && (
          <Image
            src="/assets/images/icons/link.svg"
            width="18px"
            height="18px"
            alt="link-icon"
          />
        )}
      </CustomLink>
    );
  }

  return (
    <Link href={to} {...props}>
      <a>
        {children}
        {isShowIcon && (
          <Image
            src="/assets/images/icons/link.svg"
            width="18px"
            height="18px"
            alt="link-icon"
          />
        )}
      </a>
    </Link>
  );
};

export default Redirect;
