import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AndOperationType,
  GroupByFieldType,
  OrOperationType,
  RangeFilters,
} from 'api/graphql';
import { FilterFieldKey } from 'types/filterCheckbox';

type State = {
  selectedSaleTypeFilter: string;
  selectedSortByTypeFilter: string;
  isBuyNowActive: boolean;
  isBundleActive: boolean;
  andOperations: AndOperationType[];
  orOperations: OrOperationType[][];
  rangeFilters: RangeFilters[];
  filterKeys: FilterFieldKey;
  groupByFiledKey: GroupByFieldType[];
  prevUrl?: string;
  isLoading?: boolean;
};

const initialState: State = {
  selectedSaleTypeFilter: '',
  selectedSortByTypeFilter: 'Recently added',
  isBuyNowActive: false,
  isBundleActive: false,
  andOperations: [],
  orOperations: [],
  rangeFilters: [],
  filterKeys: {
    fieldKeysForGroupBy: [],
    fieldKeysForRange: [],
  },
  groupByFiledKey: [],
  prevUrl: 'nfts',
  isLoading: false,
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSelectedSaleTypeFilter: (state, action: PayloadAction<string>) => {
      state.selectedSaleTypeFilter = action.payload;
    },
    setSelectedSortByTypeFilter: (state, action: PayloadAction<string>) => {
      state.selectedSortByTypeFilter = action.payload;
    },
    setIsBuyNowActive: (state, action: PayloadAction<boolean>) => {
      state.isBuyNowActive = action.payload;
    },
    setIsBundleActive: (state, action: PayloadAction<boolean>) => {
      state.isBundleActive = action.payload;
    },
    setAndOperations: (state, action: PayloadAction<AndOperationType[]>) => {
      state.andOperations = action.payload;
    },
    setOrOperations: (state, action: PayloadAction<OrOperationType[][]>) => {
      state.orOperations = action.payload;
    },
    setRangeFilters: (state, action: PayloadAction<RangeFilters[]>) => {
      state.rangeFilters = action.payload;
    },
    setFilterKeys: (state, action: PayloadAction<FilterFieldKey>) => {
      state.filterKeys = action.payload;
    },
    setGroupBuyFiledKey: (state, action: PayloadAction<GroupByFieldType[]>) => {
      state.groupByFiledKey = action.payload;
    },
    setPrevUrl: (state, action: PayloadAction<string>) => {
      state.prevUrl = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setSelectedSaleTypeFilter,
  setSelectedSortByTypeFilter,
  setIsBuyNowActive,
  setIsBundleActive,
  setAndOperations,
  setOrOperations,
  setRangeFilters,
  setFilterKeys,
  setGroupBuyFiledKey,
  setPrevUrl,
  setIsLoading,
} = filterSlice.actions;
export default filterSlice.reducer;
