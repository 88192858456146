import api from 'api';
import qs from 'qs';
import type {
  DropsDetail,
  DropsItem,
  DropsHistoryRequest,
  DropsHistoryResponse,
  DropsSupplyResponse,
  DropsNavigationResponse,
  GetDropsCollectionListResponse,
  DropsCurrentSupplyResponse,
  DropsAlarmSettingRequest,
  DropsAlarmSettingResponse,
  RenewDropsListRequest,
  RenewDropsListResponse,
  HotDropsListResponse,
  RenewDropsRelatedRequest,
  RenewDropsRelateResponse,
} from 'types/drops';
import type ResponseResult from 'types/response';

export const getDropsList = (): Promise<ResponseResult<DropsItem[]>> =>
  api.get('/drops');

export const getMainDropsBanner = (): Promise<ResponseResult<DropsItem[]>> =>
  api.get('/drops/banner');

export const getHeaderDropsList = (): Promise<ResponseResult<DropsItem[]>> =>
  api.get('/drops/collection');

export const getHotDropsList = (): Promise<
  ResponseResult<HotDropsListResponse[]>
> => api.get('/drops/hot');

export const getRenwalDropsRelated = (
  params: RenewDropsRelatedRequest,
): Promise<ResponseResult<RenewDropsRelateResponse>> => {
  const queryString = qs.stringify({ ...params }, { skipNulls: true });
  return api.get(`/drops/relate?${queryString}`);
};

export const getRenwalDropsList = (
  params: RenewDropsListRequest,
): Promise<ResponseResult<RenewDropsListResponse>> => {
  const queryString = qs.stringify({ ...params }, { skipNulls: true });
  return api.get(`/drops/list?${queryString}`);
};

export const getDropsDetail = (
  slug: string,
): Promise<ResponseResult<DropsDetail>> => api.get(`/drops/${slug}`);

export const getDropsRenewalDetail = (
  slug: string,
): Promise<ResponseResult<DropsDetail>> => api.get(`/drops/detail/${slug}`);

export const getDropsHistory = (
  params: DropsHistoryRequest,
): Promise<ResponseResult<DropsHistoryResponse>> => {
  const queryString = qs.stringify({ ...params }, { skipNulls: true });
  return api.get(`/drops/history?${queryString}`);
};

export const getDropsSupply = (params: {
  seqDrops: number;
}): Promise<ResponseResult<DropsSupplyResponse>> => {
  const queryString = qs.stringify({ ...params }, { skipNulls: true });
  return api.get(`/drops/supply/total?${queryString}`);
};

export const setDropsStatus = (params: {
  seqDrops: number;
}): Promise<ResponseResult<DropsSupplyResponse>> => {
  const queryString = qs.stringify({ ...params }, { skipNulls: true });
  return api.patch(`/drops/item/status?${queryString}`);
};

export const getDropsNavigation = (): Promise<
  ResponseResult<DropsNavigationResponse[]>
> => api.get('/drops/collection');

export const getDropsCollectionList = (data: {
  collectionId: number;
}): Promise<ResponseResult<GetDropsCollectionListResponse>> =>
  api.get(`/drops/collection/${data.collectionId}`);

export const patchDropsCurrentSupply = ({
  seqDrops,
  totalSellCount: currentSupply,
}: DropsCurrentSupplyResponse) =>
  api.patch(`/drops/current?seqDrops=${seqDrops}`, {
    currentSupply,
  });

export const patchDropsAlarmSetting = ({
  address,
  status,
  collectionId,
}: DropsAlarmSettingRequest): Promise<DropsAlarmSettingResponse> =>
  api.patch(`/alarm/setting/drops`, { address, status, collectionId });
