import { gql } from 'graphql-request';
import { membersGraphQLClient } from '.';

const GET_MEMBERS_STAKED_TOKEN = gql`
  query GetStaked($id: String) {
    staked(id: $id) {
      id
      user
      tokenId
      tokenAddress
    }
  }
`;

/**
 * @param id 지갑 주소
 */
const getMembersStakedToken = async (id: string) => {
  const data = await membersGraphQLClient.request(GET_MEMBERS_STAKED_TOKEN, {
    id,
  });
  const { staked } = data;

  return {
    ...staked,
  };
};

export default getMembersStakedToken;
