import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  isSelectAdvertise: boolean;
};

const initialState: State = {
  isSelectAdvertise: false,
};

const advertiseSlice = createSlice({
  name: 'advertise',
  initialState,
  reducers: {
    setIsSelectAdvertise: (state, action: PayloadAction<boolean>) => {
      state.isSelectAdvertise = action.payload;
    },
  },
});

export const { setIsSelectAdvertise } = advertiseSlice.actions;

export default advertiseSlice.reducer;
