import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  isModalOpen: boolean;
  isDrawerOpen?: boolean;
};

const initialState: State = {
  isModalOpen: false,
  isDrawerOpen: false,
};

const LoginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const { setIsModalOpen, setIsDrawerOpen } = LoginSlice.actions;
export default LoginSlice.reducer;
