import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderStatus, SelectedItem } from 'types/assets';
import { CollectionResponseData } from 'types/collections';

type SelectedBundleOrderInfo = {
  orderId: string;
  name: string;
  price: string;
  currency: string;
};

type State = {
  selectedMyItemsCollection: CollectionResponseData | null;
  selectedMySalesCollection: CollectionResponseData | null;
  selectedMySalesOrderFilter: Record<OrderStatus, boolean>;
  selectedItems: SelectedItem[];
  selectedBundleInfo: SelectedBundleOrderInfo;
  isSwitchBundle: boolean;
  firstCategory: string | null;
};

const initialState: State = {
  selectedMyItemsCollection: null,
  selectedMySalesCollection: null,
  selectedMySalesOrderFilter: { sale: false, offer: false },
  selectedItems: [],
  selectedBundleInfo: { orderId: '', name: '', price: '', currency: 'PLA' },
  isSwitchBundle: false,
  firstCategory: null,
};

const myAccountSlice = createSlice({
  name: 'my-account',
  initialState,
  reducers: {
    setSelectedMyItemsCollection: (
      state,
      action: PayloadAction<CollectionResponseData | null>,
    ) => {
      state.selectedMyItemsCollection = action.payload;
    },
    setSelectedMySalesCollection: (
      state,
      action: PayloadAction<CollectionResponseData | null>,
    ) => {
      state.selectedMySalesCollection = action.payload;
    },
    setSelectedMySalesOrderFilter: (
      state,
      action: PayloadAction<Record<OrderStatus, boolean>>,
    ) => {
      state.selectedMySalesOrderFilter = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<SelectedItem[]>) => {
      state.selectedItems = action.payload;
    },
    setSelectedBundleInfo: (
      state,
      action: PayloadAction<SelectedBundleOrderInfo>,
    ) => {
      state.selectedBundleInfo = action.payload;
    },
    setIsSwitchBundle(state, action: PayloadAction<boolean>) {
      state.isSwitchBundle = action.payload;
    },
    setFirstCategory: (state, action: PayloadAction<string | null>) => {
      state.firstCategory = action.payload;
    },
  },
});

export const {
  setSelectedMyItemsCollection,
  setSelectedMySalesCollection,
  setSelectedMySalesOrderFilter,
  setSelectedItems,
  setSelectedBundleInfo,
  setIsSwitchBundle,
  setFirstCategory,
} = myAccountSlice.actions;
export default myAccountSlice.reducer;
