import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { KaikasConnector } from 'kaikas-connector';

import { activeChainId, rpcUrls } from 'lib/network';
import { TorusConnector } from './torus-connector';
import { PortisConnector } from './portis-connector';

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 11155111, 42, 137, 80002, 1001, 8217],
});

export const ethereumPortis = new PortisConnector({
  dAppId: 'e539fba6-1daf-4d7e-81e3-de750481ab6b',
  networks: [activeChainId.ethereum],
  nodeUrl: rpcUrls[activeChainId.ethereum],
});

export const polygonPortis = new PortisConnector({
  dAppId: 'e539fba6-1daf-4d7e-81e3-de750481ab6b',
  networks: [activeChainId.polygon],
  nodeUrl: rpcUrls[activeChainId.polygon],
});

export const ethereumWalletConnect = new WalletConnectConnector({
  supportedChainIds: [activeChainId.ethereum],
  rpc: { [activeChainId.ethereum]: rpcUrls[activeChainId.ethereum] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const polygonWalletConnect = new WalletConnectConnector({
  supportedChainIds: [activeChainId.polygon],
  rpc: { [activeChainId.polygon]: rpcUrls[activeChainId.polygon] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const ethereumWalletLink = new WalletLinkConnector({
  url: rpcUrls[activeChainId.ethereum],
  appName: 'PlayDapp Marketplace',
});

export const polygonWalletLink = new WalletLinkConnector({
  url: rpcUrls[activeChainId.polygon],
  appName: 'PlayDapp Marketplace',
});

export const ethereumTorusWallet = new TorusConnector({
  chainId: activeChainId.ethereum,
  initOptions: {
    network: { host: activeChainId.ethereum === 1 ? 'mainnet' : 'sepolia' },
  },
});

export const polygonTorusWallet = new TorusConnector({
  chainId: activeChainId.polygon,
  initOptions: {
    network: { host: activeChainId.polygon === 137 ? 'matic' : 'amoy' },
  },
});

export const klaytnKaikasWalllet = new KaikasConnector({
  supportedChainIds: [activeChainId.klaytn],
});

const connectorList = {
  metamask: injected,
  ethereumPortis,
  polygonPortis,
  ethereumWalletConnect,
  polygonWalletConnect,
  ethereumWalletLink,
  polygonWalletLink,
  polygonTorusWallet,
  ethereumTorusWallet,
  klaytnKaikasWalllet,
};

export const connectorsByNetwork = {
  ethereum: {
    metamask: injected,
    portis: ethereumPortis,
    walletconnect: ethereumWalletConnect,
    walletlink: ethereumWalletLink,
    torus: ethereumTorusWallet,
    phantom: null,
    glow: null,
    kaikas: null,
  },
  polygon: {
    metamask: injected,
    portis: polygonPortis,
    walletconnect: polygonWalletConnect,
    walletlink: polygonWalletLink,
    torus: polygonTorusWallet,
    phantom: null,
    glow: null,
    kaikas: null,
  },
  solana: {
    metamask: null,
    portis: null,
    walletconnect: null,
    walletlink: null,
    torus: null,
    phantom: 'Phantom',
    glow: 'Glow',
    kaikas: null,
  },
  klaytn: {
    metamask: injected,
    portis: null,
    walletconnect: null,
    walletlink: null,
    torus: null,
    phantom: null,
    glow: null,
    kaikas: klaytnKaikasWalllet,
  },
};

export default connectorList;
