import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { Hydrate } from 'react-query';
import { Global } from '@emotion/react';
import NProgress from 'nprogress';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';

import styles from 'styles';
import { getCountry } from 'api/graphql/util';
import type { NextPageWithLayout } from 'types/next-page';

import AppProvider from '@/components/AppProvider';
import ErrorBoundary from '@/components/ErrorBoundary';
import GoogleTagManagerScript from '@/components/GoogleTagManagerScript';
import Initialize from '@/components/Initialize';
import MergeStatus from '@/components/MergeStatus';
import NotAvailable from '@/components/NotAvailable';

import 'nprogress/nprogress.css';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const VConsole = dynamic(() => import('../components/VConsole'), {
  ssr: false,
});

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

NProgress.configure({
  minimum: 0.2,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
});

// mock service worker
const isEnabledMockServiceWorker =
  process.env.NEXT_PUBLIC_ENV === 'development' &&
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';
if (isEnabledMockServiceWorker) {
  if (typeof window === 'undefined') {
    (async () => {
      const { server } = await import('mocks/server');
      server.listen();
    })();
  } else {
    (async () => {
      const { worker } = await import('mocks/browser');
      worker.start();
    })();
  }
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const { dehydratedState } = pageProps as { dehydratedState: unknown };
  const getLayout = Component.getLayout ?? ((page) => page);

  const [isAvailable, setIsAvailable] = useState(true);

  const router = useRouter();

  useEffect(() => {
    (async () => {
      try {
        const res = await getCountry();

        if (res === 'HK') {
          setIsAvailable(false);
        }
      } catch {
        setIsAvailable(true);
      }
    })();
  }, []);

  return (
    <>
      <GoogleTagManagerScript />

      <ErrorBoundary path={router.asPath}>
        {process.env.NEXT_PUBLIC_ENV === 'development' && <VConsole />}
        <AppProvider>
          <MergeStatus />
          <Hydrate state={dehydratedState}>
            <Initialize />
            <Global styles={styles} />
            {isAvailable ? (
              getLayout(<Component {...pageProps} />)
            ) : (
              <NotAvailable />
            )}
          </Hydrate>
        </AppProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
