import { palette } from '@playdapp/ui';

export type Theme = {
  text: string;
  background: string;
  rem: (number: number) => string;
  range: {
    [key in string]: string;
  };
};

const rem = (num: number): string => `${(num / 16).toFixed(4)}rem`;

export const lightTheme: Theme = {
  text: palette.black,
  background: palette.white,
  rem,
  range: {
    track: palette.gray400,
    filledTrack: '#0D917B',
    thumb: '#12CEB0',
  },
};

export const darkTheme: Theme = {
  text: palette.white,
  background: palette.black,
  rem,
  range: {
    track: palette.gray400,
    filledTrack: '#0D917B',
    thumb: '#12CEB0',
  },
};
