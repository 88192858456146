import { auth } from 'api/user';
import { UserAuthRequest } from 'types/user';

export const onLogin = async ({
  account,
  provider,
  network,
  sign,
  version,
  isStorageSave = true,
}: UserAuthRequest) => {
  const resAuth = await auth({ account, provider, sign, network, version });

  if (resAuth.status === 200 && resAuth.data) {
    const { token } = resAuth.data;

    if (isStorageSave) {
      localStorage.setItem('PDMP_AUTH_TOKEN', token);
      localStorage.setItem('PDMP_WALLET_PROVIDER', provider);
      localStorage.setItem('PDMP_NETWORK', network);
      localStorage.setItem('PDMP_SIGN', sign);
      localStorage.setItem('PDMP_ADDRESS', account);
    }

    return token;
  }

  return '';
};

export const removeLoginInfo = () => {
  localStorage.removeItem('PDMP_AUTH_TOKEN');
  localStorage.removeItem('PDMP_WALLET_PROVIDER');
  localStorage.removeItem('PDMP_NETWORK');
  localStorage.removeItem('PDMP_SIGN');
  localStorage.removeItem('PDMP_ADDRESS');
};

export const isTokenExist = () => !!localStorage.getItem('PDMP_AUTH_TOKEN');

export const getNetwork = () => localStorage.getItem('PDMP_NETWORK');
