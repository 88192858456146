import { TabList } from 'types/merge';

export const PLAYDAPP_TOWN_TAB_LIST: TabList = [
  {
    tab: 'PLAYDAPP_TOWN_R',
    text: 'R > SR',
  },
  {
    tab: 'PLAYDAPP_TOWN_SR',
    text: 'SR > SSR',
  },
];

export const PLAYZ_V1_ASSET_ID = {
  137: 1,
  80002: 8,
};

export const PLAYZ_V2_ASSET_ID = {
  137: 9,
  80002: 6,
};

export const PLAYDAPP_DRAGON_ASSET_ID = {
  137: 4,
  80002: 4,
};

export const ASSET_IDS = {
  PLAYDAPP_TOWN: {
    137: [
      { key: 'assetId', value: '1' },
      { key: 'assetId', value: '9' },
    ],
    80002: [
      { key: 'assetId', value: '6' },
      { key: 'assetId', value: '8' },
    ],
  },
  PLAYDAPP_DRAGON: {
    137: [{ key: 'assetId', value: '4' }],
    80002: [{ key: 'assetId', value: '4' }],
  },
};

export const MAX_SELECT_COUNT = 6;

export const INIT_DRAGON_R_LIST = [
  {
    name: 'aeormara',
    image: '/merge/aeormara-r.png',
    apiName: 'Aeormara R',
  },
  {
    name: 'brisalid',
    image: '/merge/brisalid-r.png',
    apiName: 'Brisalid R',
  },
  {
    name: 'failaker',
    image: '/merge/failaker-r.png',
    apiName: 'Failaker R',
  },
  {
    name: 'hrosveru',
    image: '/merge/hrosveru-r.png',
    apiName: 'Hrosveru R',
  },
  {
    name: 'palamekid',
    image: '/merge/palamekid-r.png',
    apiName: 'Palamekid R',
  },
  {
    name: 'tyconros',
    image: '/merge/tyconros-r.png',
    apiName: 'Tyconros R',
  },
];

export const INIT_TOWN_R_LIST = [
  {
    name: 'bram',
    image: '/merge/bram',
    apiName: 'BRAM R',
  },
  {
    name: 'hikimo',
    image: '/merge/hikimo',
    apiName: 'HIKIMO R',
  },
  {
    name: 'lance',
    image: '/merge/lance',
    apiName: 'LANCE R',
  },
  {
    name: 'mikey',
    image: '/merge/mikey',
    apiName: 'MIKEY R',
  },
  {
    name: 'peppa',
    image: '/merge/peppa',
    apiName: 'PEPPA R',
  },
  {
    name: 'twinky',
    image: '/merge/twinky',
    apiName: 'TWINKY R',
  },
];

export const INIT_TOWN_SR_LIST = [
  {
    name: 'bram',
    image: '/merge/bram',
    apiName: 'BRAM SR',
  },
  {
    name: 'hikimo',
    image: '/merge/hikimo',
    apiName: 'HIKIMO SR',
  },
  {
    name: 'lance',
    image: '/merge/lance',
    apiName: 'LANCE SR',
  },
  {
    name: 'mikey',
    image: '/merge/mikey',
    apiName: 'MIKEY SR',
  },
  {
    name: 'peppa',
    image: '/merge/peppa',
    apiName: 'PEPPA SR',
  },
  {
    name: 'twinky',
    image: '/merge/twinky',
    apiName: 'TWINKY SR',
  },
];

export const INIT_MERGE_AVAILABLE = {
  playz: {
    R: false,
    SR: false,
  },
  dragon: {
    R: false,
  },
};

export const TOAST_ID = 'mergeLoading';

export const PURCHASE_LINK = {
  PLAYDAPP_TOWN: {
    137: '/collection/polygon/1',
    80002: '/collection/polygon/1',
  },
  PLAYDAPP_DRAGON: {
    137: '/collection/polygon/3',
    80002: '/collection/polygon/6',
  },
};

export const MERGE_ERROR_TEXT_LIST = [
  'Session terminated unexpectedly!',
  'Please check your collection for results',
];

export const MERGE_OWNER_CHECK_ERROR_TEXT_LIST = [
  'Please try again in a few minutes to proceed with the Merge & Upgrade with this NFT.',
];
