/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
import React, { useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Web3ReactProvider } from '@web3-react/core';
import {
  Web3Provider,
  ExternalProvider,
  JsonRpcFetchFunc,
} from '@ethersproject/providers';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { clusterApiUrl } from '@solana/web3.js';
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, sepolia, polygon } from 'wagmi/chains';

import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';

import { store } from 'store';
import { network } from 'data/marketplace';

import ThemeProvider from '@/contexts/ThemeContext';

const polygonAmoy = {
  id: 80002,
  name: 'Polygon Amoy',
  network: 'polygon_amoy',
  nativeCurrency: {
    decimals: 18,
    name: 'MATIC',
    symbol: 'MATIC',
  },
  rpcUrls: {
    public: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
    default: {
      http: ['https://rpc-amoy.polygon.technology'],
    },
  },

  blockExplorers: {
    etherscan: {
      name: 'PolygonScan',
      url: 'https://www.oklink.com/amoy',
    },
    default: {
      name: 'PolygonScan',
      url: 'https://www.oklink.com/amoy',
    },
  },
} as const;

function getLibrary(
  provider: ExternalProvider | JsonRpcFetchFunc,
): Web3Provider {
  const library = new Web3Provider(provider, 'any');
  library.pollingInterval = 8000;
  return library;
}

type Props = {
  children: React.ReactNode;
};

const projectId = '6c45f70148eee001e357f958c6ab5909';

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, sepolia, polygon, polygonAmoy],
  [walletConnectProvider({ projectId })],
);

const client = createClient({
  provider,
  webSocketProvider,
  connectors: modalConnectors({
    projectId,
    appName: 'PlayDapp Marketplace',
    version: '1',
    chains,
  }),
});

const ethereumClient = new EthereumClient(client, chains);

const BlockChainProvider = ({ children }: Props) => {
  const toast = useToast();

  const endpoint =
    process.env.NEXT_PUBLIC_SOLANA_SDK_END_POINT || clusterApiUrl(network);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter({
        network,
      }),
      new GlowWalletAdapter({
        network,
      }),
    ],
    [],
  );

  return (
    <>
      <WagmiConfig client={client}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider
            onError={(e) => {
              toast({
                title: e.name,
                description: e.message,
                position: 'bottom-left',
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
            }}
            wallets={wallets}
            autoConnect
          >
            <Web3ReactProvider getLibrary={getLibrary}>
              {children}
            </Web3ReactProvider>
          </WalletProvider>
        </ConnectionProvider>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
};

const AppProvider = ({ children }: Props) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <Provider store={store}>
      <ChakraProvider>
        <BlockChainProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ThemeProvider>
              <ParallaxProvider>{children}</ParallaxProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </BlockChainProvider>
      </ChakraProvider>
    </Provider>
  );
};

export default AppProvider;
