import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import getMembersStakedToken from 'api/graphql/getMembersStakedToken';
import { getUser } from 'api/user';
import { ResponseError } from 'api/error';

import type { UserResponseData } from 'types/user';

export type ConnectProps = {
  address: string;
  alias: string;
  originalAlias: string;
};

type MembersMikeyGrade = 'risingstar-mikey' | 'underground-mikey' | null;

type State = {
  isUserLoading: boolean;
  active: boolean;
  email: string;
  nickname: string;
  isVerified: boolean;
  termsConsent: boolean;
  marketingConsent: boolean;
  alias: string;
  profileTokenId: string | null;
  membersMikeyGrade: MembersMikeyGrade;
  connected: { address: string; alias: string }[];
  currentRequestId?: string;
  userImage: string | null;
  isError: boolean;
  errorMessage: string;
};

type FetchUserInfoParams = {
  account: string;
};

type MemberGrade = {
  grade: MembersMikeyGrade;
  tokenAddress: string;
};

type FetchUserProfileResponse = {
  profileTokenId: string | null;
  membersMikeyGrade: MembersMikeyGrade | null;
};

const memberGradeList: MemberGrade[] = [
  {
    grade: 'risingstar-mikey',
    tokenAddress: '0x90c0cb30823d23d7874a5a6290e2edc082e807c8',
  },
  {
    grade: 'underground-mikey',
    tokenAddress: '0x7546d34fc15b25471072493e25150e0a8901cd52',
  },
];

const initialState: State = {
  isUserLoading: true,
  active: false,
  email: '',
  nickname: '',
  isVerified: false,
  termsConsent: false,
  marketingConsent: false,
  alias: '',
  profileTokenId: null,
  membersMikeyGrade: null,
  connected: [],
  currentRequestId: undefined,
  userImage: '',
  isError: false,
  errorMessage: '',
};

export const fetchUserInfo = createAsyncThunk<
  UserResponseData,
  FetchUserInfoParams,
  { state: RootState }
>(
  'user/fetchUserInfo',
  async ({ account }: FetchUserInfoParams, { rejectWithValue }) => {
    try {
      const response = await getUser({ account });
      if (response.status === 200 && response.data) {
        return response.data;
      }
      throw new Error('Failed to get user info');
    } catch (e) {
      return rejectWithValue(
        e instanceof ResponseError ? e.message : 'Unknown error occurred',
      );
    }
  },
);

export const fetchUserProfile = createAsyncThunk<
  FetchUserProfileResponse,
  FetchUserInfoParams,
  { state: RootState }
>(
  'user/fetchUserProfile',
  async ({ account }: FetchUserInfoParams, { rejectWithValue }) => {
    try {
      if (!account) {
        throw new Error('No account');
      }

      const { tokenId = null, tokenAddress = null } =
        await getMembersStakedToken(account.toLowerCase());

      const findMembersMikey = memberGradeList.find(
        (item) => item.tokenAddress === tokenAddress,
      );

      return {
        profileTokenId: tokenId,
        membersMikeyGrade: findMembersMikey ? findMembersMikey.grade : null,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    readyToUpdate: (state) => {
      state.active = true;
    },
    setUserLoading: (state, action) => {
      state.isUserLoading = action.payload;
    },
    resetUserInfo: () => ({
      ...initialState,
      isUserLoading: false,
      isError: false,
      isErrorMessage: '',
    }),
    setAlias: (state, action) => {
      state.alias = action.payload;
    },
    setProfileTokenId: (state, action) => {
      state.profileTokenId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.isUserLoading = true;
      state.isError = false;
      state.errorMessage = '';
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.isUserLoading = false;
      state.active = true;
      state.email = action.payload.email;
      state.nickname = action.payload.nickname;
      state.isVerified = action.payload.isVerified;
      state.termsConsent = action.payload.termsConsent;
      state.marketingConsent = action.payload.marketingConsent;
      state.alias = action.payload.alias;
      state.connected = action.payload.connected;
      state.currentRequestId = undefined;
      state.isError = false;
      state.errorMessage = '';
    });
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.isUserLoading = false;
      state.isError = true;
      state.errorMessage = action.payload as string;
    });
    builder.addCase(fetchUserProfile.pending, (state, action) => {
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.profileTokenId = action.payload.profileTokenId;
      state.membersMikeyGrade = action.payload.membersMikeyGrade;
    });
    builder.addCase(fetchUserProfile.rejected, (state) => {
      state.profileTokenId = null;
    });
  },
});

export const {
  readyToUpdate,
  setUserLoading,
  resetUserInfo,
  setAlias,
  setProfileTokenId,
} = userSlice.actions;

export default userSlice.reducer;
