import api from 'api';
import qs from 'qs';
import type {
  UserBundleListRequest,
  UserBundleListResponse,
} from 'types/assets';
import type {
  DetailRequest,
  LikeItemRequest,
  LikeBundleRequest,
  RefreshItemRequest,
  AssetTokenAddressRequest,
  AssetsTokenAddressRequest,
  BundleRequest,
  BidRequest,
  AuctionEmailRequest,
} from 'types/detail';
import type ResponseResult from 'types/response';
import type { BundleListRequest, BundleListResponse } from 'types/collections';

export const getDetail = ({
  contractAddress,
  tokenId,
  network,
  email,
}: DetailRequest) =>
  api.get(
    `/item/${contractAddress}/${tokenId}?network=${network}${
      email ? `&email=${email}` : ``
    }`,
  );

export const likeItem = ({
  tokenId,
  contractAddress,
  network,
  email,
  likeStatus,
}: LikeItemRequest) =>
  api.post(`/item/like`, {
    network,
    tokenAddress: contractAddress,
    tokenId,
    email,
    status: likeStatus,
  });

export const likeBundle = ({
  network,
  slug,
  email,
  likeStatus,
}: LikeBundleRequest) =>
  api.post(`/bundle/like`, { network, slug, email, status: likeStatus });

export const refreshItem = ({
  contractAddress,
  tokenId,
  network,
}: RefreshItemRequest) =>
  api.patch(`/item/metadata?network=${network}`, {
    address: contractAddress,
    tokenId,
  });

export const getAssetTokenAddress = ({
  assetId,
  tokenId,
}: AssetTokenAddressRequest) =>
  api.post('/item/asset', {
    assetId,
    tokenId,
  });

export const getAssetsTokenAddress = ({ assets }: AssetsTokenAddressRequest) =>
  api.post(`/item/assets`, {
    assets,
  });

export const getBundleDetail = ({ slug, network, email }: BundleRequest) =>
  api.get(
    `/bundle/${slug}?network=${network}${email ? `&email=${email}` : ``}`,
  );

export const getUserBundleList = ({
  network,
  page,
  categoryId,
}: UserBundleListRequest): Promise<ResponseResult<UserBundleListResponse>> => {
  const queryString = qs.stringify(
    { network, page, categoryId },
    { skipNulls: true },
  );
  return api.get(`/bundle/user/list?${queryString}`);
};

export const getBundleList = ({
  page,
  sortBy,
  sort,
  category,
  network,
}: BundleListRequest): Promise<ResponseResult<BundleListResponse>> => {
  const queryString = qs.stringify(
    { page, sortBy, sort, category, network },
    { skipNulls: true },
  );
  return api.get(`/bundle/list?${queryString}`);
};

export const getBidOrder = ({ assetId, tokenId, orderId }: BidRequest) => {
  const queryString = qs.stringify({ orderId }, { skipNulls: true });
  return api.get(`/auction/${assetId}/${tokenId}?${queryString}`);
};

export const auctionEmailAgree = ({ assetId, tokenId }: AuctionEmailRequest) =>
  api.post(`/auction/notice/${assetId}/${tokenId}`);
