/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConnectorUpdate } from '@web3-react/types';
import { AbstractConnector } from '@web3-react/abstract-connector';

const chainIdToNetwork: { [network: number]: string } = {
  1: 'mainnet',
  3: 'ropsten',
  4: 'rinkeby',
  11155111: 'sepolia',
  42: 'kovan',
  100: 'xdai',
  30: 'orchid',
  31: 'orchidTestnet',
  99: 'core',
  77: 'sokol',
  61: 'classic',
  8: 'ubiq',
  108: 'thundercore',
  18: 'thundercoreTestnet',
  163: 'lightstreams',
  122: 'fuse',
  137: 'matic',
  80002: 'maticAmoy',
};

type Network = number | { chainId: string; [key: string]: any };

interface PortisConnectorArguments {
  dAppId: string;
  networks: Network[];
  nodeUrl?: string;
  config?: any;
}

export class PortisConnector extends AbstractConnector {
  private readonly dAppId: string;

  private readonly networks: Network[];

  private readonly nodeUrl: string | undefined;

  private readonly config: any;

  public portis: any;

  constructor({
    dAppId,
    networks,
    nodeUrl,
    config = {},
  }: PortisConnectorArguments) {
    const chainIds = networks.map((n): number =>
      typeof n === 'number' ? n : Number(n.chainId),
    );
    super({ supportedChainIds: chainIds });

    this.dAppId = dAppId;
    this.networks = networks;
    this.nodeUrl = nodeUrl;
    this.config = config;

    this.handleOnLogout = this.handleOnLogout.bind(this);
    this.handleOnActiveWalletChanged =
      this.handleOnActiveWalletChanged.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
  }

  private handleOnLogout(): void {
    this.emitDeactivate();
  }

  private handleOnActiveWalletChanged(account: string): void {
    this.emitUpdate({ account });
  }

  private handleOnError(error: Error): void {
    this.emitError(error);
  }

  public async activate(): Promise<ConnectorUpdate> {
    const networkName =
      typeof this.networks[0] === 'number'
        ? chainIdToNetwork[this.networks[0]]
        : (this.networks[0] as any);
    const network = this.nodeUrl
      ? {
          nodeUrl: this.nodeUrl,
          chainId: this.networks[0],
        }
      : networkName;
    if (!this.portis) {
      const Portis = await import('@portis/web3').then((m) => m?.default ?? m);
      this.portis = new Portis(this.dAppId, network, this.config);
    }

    this.portis.onLogout(this.handleOnLogout);
    this.portis.onActiveWalletChanged(this.handleOnActiveWalletChanged);
    this.portis.onError(this.handleOnError);

    const account = await this.portis.provider
      .enable()
      .then((accounts: string[]): string => accounts[0]);

    return { provider: this.portis.provider, account };
  }

  public async showPortis(): Promise<any> {
    return this.portis.showPortis();
  }

  public async getProvider(): Promise<any> {
    return this.portis.provider;
  }

  public async getChainId(): Promise<number | string> {
    return this.portis.provider.send('eth_chainId');
  }

  public async getAccount(): Promise<string | null> {
    return this.portis.provider
      .send('eth_accounts')
      .then((accounts: string[]): string => accounts[0]);
  }

  public deactivate() {
    this.portis.onLogout(() => {});
    this.portis.onActiveWalletChanged(() => {});
    this.portis.onError(() => {});
  }

  public async changeNetwork(
    newNetwork: Network | number,
    isGasRelayEnabled?: boolean,
  ) {
    if (typeof newNetwork === 'number') {
      this.portis.changeNetwork(
        chainIdToNetwork[newNetwork],
        isGasRelayEnabled,
      );
      this.emitUpdate({ chainId: newNetwork });
    } else {
      this.portis.changeNetwork(newNetwork, isGasRelayEnabled);
      this.emitUpdate({ chainId: Number(newNetwork.chainId) });
    }
  }

  public async close() {
    await this.portis.logout();
    this.emitDeactivate();
  }
}

export default PortisConnector;
