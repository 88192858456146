import Image from 'next/image';
import type { ImageProps } from 'next/image';

/**
 * 2022-09-01 목 MJ
 * solana 추가하면서 관련 type이 지정되지 않아서 type error가 많이납니다.
 * 지금 당장 구할 수 있는 것들이 아니여서 임시로 string 타입을 추가했습니다.
 * 이건 Icon 이미지 구해지는데로 빼도록 하겠습니다.
 */
export type Icons =
  | 'alarm'
  | 'analyse'
  | 'approve-gray'
  | 'approve'
  | 'arrow-down-dgray'
  | 'arrow-down-white'
  | 'arrow-down'
  | 'arrow-left-disable'
  | 'arrow-left-v2'
  | 'arrow-right-disable'
  | 'arrow-right-v2'
  | 'arrow-right-v3'
  | 'arrow-right-v4-white'
  | 'arrow-right-v4'
  | 'arrow-right'
  | 'arrow-up-white'
  | 'arrow-up'
  | 'bell-icon'
  | 'bell-padding-white'
  | 'bell-padding'
  | 'bell-ringing-white'
  | 'bell-ringing'
  | 'bell-white'
  | 'bell'
  | 'blue-bell'
  | 'bottle-check'
  | 'box-x'
  | 'brand-ethereum'
  | 'brand-klaytn'
  | 'brand-logo'
  | 'brand-market-white'
  | 'brand-market'
  | 'brand-polygon'
  | 'brand-solana'
  | 'cancel-mark'
  | 'cancel'
  | 'card'
  | 'change'
  | 'check-awesome'
  | 'check-green'
  | 'check-mark-white'
  | 'check-primary'
  | 'check-solid'
  | 'check'
  | 'chevron-down-0206'
  | 'chevron-down'
  | 'chevron-left-green'
  | 'chevron-left-primary'
  | 'chevron-left-white-v2'
  | 'chevron-left-white'
  | 'chevron-left'
  | 'chevron-right-gray'
  | 'chevron-right-green'
  | 'chevron-right-nopadding'
  | 'chevron-right-white-v2'
  | 'chevron-right-white'
  | 'chevron-right'
  | 'close-circle-gray'
  | 'close-circle'
  | 'close-gray'
  | 'close-primary'
  | 'close-red'
  | 'close'
  | 'coin-market-cap'
  | 'complete-gray'
  | 'complete'
  | 'copy-dgray100'
  | 'copy'
  | 'cryptocom'
  | 'currency-eth'
  | 'currency-klay'
  | 'currency-matic-dark'
  | 'currency-matic'
  | 'currency-pla'
  | 'currency-sol'
  | 'currency-weth'
  | 'currency-wklay'
  | 'discord-dark'
  | 'discord'
  | 'dot-green'
  | 'dots-dgray'
  | 'dots-vertical'
  | 'dots'
  | 'down-arrow'
  | 'earth-white'
  | 'earth'
  | 'edit'
  | 'email-check'
  | 'email'
  | 'empty'
  | 'error-blue'
  | 'error-primary2'
  | 'error-yellow'
  | 'ethereum'
  | 'event-arrow'
  | 'exchange'
  | 'expand-more-gray600'
  | 'export-dgray'
  | 'export'
  | 'external'
  | 'facebook'
  | 'filter-primary'
  | 'filter'
  | 'fire'
  | 'frenz-play'
  | 'gateio'
  | 'gift'
  | 'glow-square'
  | 'glow'
  | 'grid-blank'
  | 'grid-primary'
  | 'grid-white'
  | 'heart-bundle-gray'
  | 'heart-bundle-red'
  | 'heart-single-gray'
  | 'heart-single-red'
  | 'home-active'
  | 'home'
  | 'huobi'
  | 'initialize'
  | 'insta-dark'
  | 'ipx'
  | 'kaikas-square'
  | 'kaikas'
  | 'kb-ethereum'
  | 'kb-klaytn'
  | 'kb-polygon'
  | 'kb-solana'
  | 'klaytn'
  | 'link-dark'
  | 'link-white'
  | 'link'
  | 'log-switch'
  | 'logo-md'
  | 'mask-ethereum-m'
  | 'mask-ethereum'
  | 'mask-klaytn-m'
  | 'mask-klaytn'
  | 'mask-polygon-m'
  | 'mask-polygon'
  | 'mask-solana-m'
  | 'mask-solana'
  | 'medium'
  | 'menu-account'
  | 'menu-padding-white'
  | 'menu-padding'
  | 'menu-white'
  | 'menu'
  | 'merge-bottom-arrow-gray'
  | 'merge-bottom-arrow'
  | 'merge-right-arrow-gray'
  | 'merge-right-arrow'
  | 'metamask-square'
  | 'metamask'
  | 'moonpay'
  | 'moonpayLogo'
  | 'my'
  | 'nft-demo'
  | 'ooz-logo'
  | 'phantom-square'
  | 'phantom'
  | 'plus'
  | 'polygon'
  | 'portis-square'
  | 'portis'
  | 'power'
  | 'profile'
  | 'question-mark-light-gray'
  | 'question-mark'
  | 'question'
  | 'random-plus'
  | 'refresh-primary'
  | 'refresh-white'
  | 'refresh'
  | 'right-angle'
  | 'scroll'
  | 'search-black'
  | 'search-padding-white'
  | 'search-padding'
  | 'search-white'
  | 'search'
  | 'selected-bold'
  | 'selected-large'
  | 'selected-primary100'
  | 'selected-small'
  | 'selected-white-large'
  | 'selected-white-small'
  | 'selected'
  | 'setting-green'
  | 'setting'
  | 'share-white-noborder'
  | 'share-white'
  | 'share'
  | 'simplex'
  | 'simplexLogo'
  | 'solana'
  | 'sound-off'
  | 'sound-on'
  | 'square-blank'
  | 'square-white'
  | 'stakingbtn'
  | 'swap'
  | 'telegram'
  | 'toast-close-error'
  | 'toast-close-loading'
  | 'toast-close-success'
  | 'toast-close-warning'
  | 'toast-error'
  | 'toast-loading'
  | 'toast-success'
  | 'toast-warning'
  | 'torus-square'
  | 'torus'
  | 'transak'
  | 'transakLogo'
  | 'transfer'
  | 'twitter-dark'
  | 'twitter'
  | 'tx-error'
  | 'tx-success'
  | 'unlock-gray'
  | 'unlock'
  | 'up-arrow'
  | 'user-info-ethereum'
  | 'user-info-klaytn'
  | 'user-info-polygon'
  | 'user-info-solana'
  | 'user'
  | 'verify'
  | 'video-play-hover'
  | 'video-play'
  | 'video-stop-hover'
  | 'video-stop'
  | 'volume-off-white'
  | 'volume-off'
  | 'volume-on-white'
  | 'volume-on'
  | 'walletconnect-square'
  | 'walletconnect'
  | 'walletlink-square'
  | 'walletlink'
  | 'warning-yellow'
  | 'warning';

type Props = Omit<ImageProps, 'height' | 'src' | 'width'> & {
  /** 아이콘 이름 */
  name: Icons;
  /** 정방형 사이즈를 지정할 때 사용합니다 */
  size?: number | string;
  /** 아이콘의 너비를 지정합니다 */
  width?: number | string;
  /** 아이콘의 높이를 지정합니다 */
  height?: number | string;
};

/** 아이콘 이미지 컴포넌트 */
const Icon = ({ name, size, width, height, ...rest }: Props) => (
  <Image
    src={`/assets/images/icons/${name}.svg`}
    width={size || width}
    height={size || height}
    alt={`icon-${name}`}
    layout="fixed"
    priority
    draggable={false}
    {...rest}
  />
);

export default Icon;
