import { activeChainId } from 'lib/network';
import { NetworkName } from 'types/network';

type Token = {
  name: string;
  address: string;
};

type TokenAddress = {
  weth: {
    [key: number]: string;
  };
  wklay: {
    [key: number]: string;
  };
};

type SwapLink = {
  polygon: string;
  ethereum: string;
  solana: string;
  klaytn: string;
};

export const tokenAddress: TokenAddress = {
  weth: {
    1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    11155111: '0x5279C74ea8791aAAcAE23a2D8548c87992efd979',
    137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    80002: '0x8A0A41136138CED0496DBb7c30F57212D452C68c',
  },
  wklay: {
    1001: '0x043c471bee060e00a56ccd02c0ca286808a5a436',
    8217: '0x19aac5f612f524b754ca7e7c41cbfa2e981a4432',
  },
};

export const tokenName = (network: NetworkName, address: string) => {
  if (network === 'solana') return 'sol';

  const pla = {
    1: '0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430',
    11155111: '0x8E8003Df2587243bB0ce25221c2fa5a217161eF1',
    137: '0x8765f05ADce126d70bcdF1b0a48Db573316662eB',
    80002: '0xFb61c22a372Dea97EC52362187dB4394c98a7AcD',
  };

  if (
    address === pla[1] ||
    address === pla[11155111] ||
    address === pla[137] ||
    address === pla[80002]
  ) {
    return 'pla';
  }

  if (
    address === tokenAddress.weth[1] ||
    address === tokenAddress.weth[11155111] ||
    address === tokenAddress.weth[137] ||
    address === tokenAddress.weth[80002]
  ) {
    return 'weth';
  }

  if (
    address === tokenAddress.weth[1001] ||
    address === tokenAddress.weth[8217]
  ) {
    return 'klay';
  }

  // eslint-disable-next-line no-nested-ternary
  return network === 'polygon'
    ? 'matic'
    : network === 'klaytn'
    ? 'klay'
    : 'eth';
};

export const ethTokens: Token[] = [
  {
    name: 'WETH',
    address: tokenAddress.weth[activeChainId.ethereum],
  },
];

export const polygonTokens: Token[] = [
  {
    name: 'WETH',
    address: tokenAddress.weth[activeChainId.polygon],
  },
];

export const swapLinks: SwapLink = {
  polygon:
    'https://quickswap.exchange/#/swap?inputCurrency=MATIC&outputCurrency=0x8765f05ADce126d70bcdF1b0a48Db573316662eB',
  ethereum:
    'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430&chain=mainnet',
  solana: '',
  klaytn: 'https://klayswap.com/exchange/swap',
};
