import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  selectSearchInfoColor: string;
  selectIsFucus: boolean;
};

const initialState: State = {
  selectSearchInfoColor: '',
  selectIsFucus: false,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setSelectSearchInfoColor: (state, action: PayloadAction<string>) => {
      state.selectSearchInfoColor = action.payload;
    },
    setIsSelectFocus: (state, action: PayloadAction<boolean>) => {
      state.selectIsFucus = action.payload;
    },
  },
});

export const { setSelectSearchInfoColor, setIsSelectFocus } =
  headerSlice.actions;

export default headerSlice.reducer;
