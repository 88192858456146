/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import { PlaydappMarketplaceConn as SolanaConnector } from '@playdapp/exchange-sol-js';
import { PlaydappMarketplaceAPIConfig } from '@playdapp/exchange-sol-js/lib/types';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
} from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { escrowWalletMinSol, network } from 'data/marketplace';

import type { TxStatus } from 'types/transaction';

type SolanaSendStatus = 'error' | 'input' | 'loading' | 'nothing' | 'success';

type State = {
  solanaConnector: SolanaConnector | null;
  isConnectorLoading: boolean;
  txStatus: TxStatus | null;
  transactionHash: string | null;
  currentRequestId?: string;
  depositStatus: SolanaSendStatus;
  inputDeposit: number | string;
  isDepositBoxOpen: boolean;
  withdrawStatus: SolanaSendStatus;
  inputWithdraw: number | string;
  isWithdrawBoxOpen: boolean;
};

type CreateBuyOrderParams = {
  ownerAddress: string;
  tokenId: string;
  startAmount: number;
};

type CreateSellOrderParams = {
  tokenId: string;
  startAmount: number;
};

type OnlyPda = {
  pda: string;
};

type DepositToEPAParams = {
  amount: number;
};

type WithdrawFromEPAParams = {
  amount: number;
};

export const setConnector = createAsyncThunk<
  SolanaConnector,
  void,
  { state: RootState }
>(
  'marketplaceSolana/setConnector',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { solanaWalletAdapter } = getState().wallet;

      if (!solanaWalletAdapter) {
        return rejectWithValue(
          'Failed to init solana connector: solanaWalletAdapter is not found',
        );
      }

      const config: PlaydappMarketplaceAPIConfig = {
        cluster: network,
      };

      const connector: SolanaConnector = new SolanaConnector(
        solanaWalletAdapter,
        config,
      );

      await connector.setAuctionHouse();

      return connector;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const createSellOrder = createAsyncThunk<
  any | null,
  CreateSellOrderParams,
  { state: RootState }
>(
  'marketplaceSolana/createSellOrder',
  async (
    { tokenId, startAmount }: CreateSellOrderParams,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { solanaWalletAdapter, publicKey } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (!solanaConnector || !solanaWalletAdapter || !publicKey) {
        throw new Error('Failed to solana createSellOrder');
      }

      const result = await solanaConnector.createSellOrder(
        solanaWalletAdapter,
        tokenId,
        startAmount,
      );

      if (result.response.signature) {
        dispatch({
          type: 'marketplaceSolana/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const createBuyOrder = createAsyncThunk<
  any | null,
  CreateBuyOrderParams,
  { state: RootState }
>(
  'marketplaceSolana/createBuyOrder',
  async (
    { ownerAddress, tokenId, startAmount }: CreateBuyOrderParams,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const { solanaWalletAdapter, publicKey } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (
        !solanaConnector ||
        !solanaWalletAdapter ||
        !publicKey ||
        !ownerAddress
      ) {
        throw new Error('Failed to solana createBuyOrder');
      }

      const associatedTokenAddress = await getAssociatedTokenAddress(
        new PublicKey(tokenId),
        new PublicKey(ownerAddress),
        false,
        TOKEN_PROGRAM_ID,
        ASSOCIATED_TOKEN_PROGRAM_ID,
      );

      const result = await solanaConnector.createBuyOrder(
        solanaWalletAdapter,
        tokenId,
        startAmount,
        associatedTokenAddress.toString(),
      );

      if (result.response.signature) {
        dispatch({
          type: 'marketplaceSolana/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const fulfillFixedPriceSale = createAsyncThunk<
  any | null,
  OnlyPda,
  { state: RootState }
>(
  'marketplaceSolana/fulfillFixedPriceSale',
  async ({ pda }: OnlyPda, { getState, dispatch, rejectWithValue }) => {
    try {
      const { solanaWalletAdapter, publicKey } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (!solanaConnector || !solanaWalletAdapter || !publicKey) {
        throw new Error('Failed to solana fulfillFixedPriceSale');
      }

      const result = await solanaConnector.fulfillFixedPriceSale(
        solanaWalletAdapter,
        pda,
      );

      if (result.response.signature) {
        dispatch({
          type: 'marketplaceSolana/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const cancelBuyOrder = createAsyncThunk<
  any | null,
  OnlyPda,
  { state: RootState }
>(
  'marketplaceSolana/cancelOffer',
  async ({ pda }: OnlyPda, { getState, dispatch, rejectWithValue }) => {
    try {
      const { solanaWalletAdapter, publicKey } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (!solanaConnector || !solanaWalletAdapter || !publicKey) {
        throw new Error('Failed to solana cancelBuyOrder');
      }

      const result = await solanaConnector.cancelBuyOrder(
        solanaWalletAdapter,
        pda,
      );

      if (result.response.signature) {
        dispatch({
          type: 'marketplaceSolana/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const cancelSellOrder = createAsyncThunk<
  any | null,
  OnlyPda,
  { state: RootState }
>(
  'marketplaceSolana/cancelSellOrder',
  async ({ pda }: OnlyPda, { getState, dispatch, rejectWithValue }) => {
    try {
      const { solanaWalletAdapter, publicKey } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (!solanaConnector || !solanaWalletAdapter || !publicKey) {
        throw new Error('Failed to solana cancelSellOrder');
      }

      const result = await solanaConnector.cancelSellOrder(
        solanaWalletAdapter,
        pda,
      );

      if (result.response.signature) {
        dispatch({
          type: 'marketplaceSolana/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const fulfillBuyOffer = createAsyncThunk<
  any | null,
  OnlyPda,
  { state: RootState }
>(
  'marketplaceSolana/fulfillBuyOffer',
  async ({ pda }: OnlyPda, { getState, dispatch, rejectWithValue }) => {
    try {
      const { solanaWalletAdapter, publicKey } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (!solanaConnector || !solanaWalletAdapter || !publicKey) {
        throw new Error('Failed to solana fulfillBuyOffer');
      }

      const result = await solanaConnector.fulfillBuyOffer(
        solanaWalletAdapter,
        pda,
      );

      if (result.response.signature) {
        dispatch({
          type: 'marketplaceSolana/successTxStatus',
        });
      }

      return result;
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const depositToEPA = createAsyncThunk<
  any | null,
  DepositToEPAParams,
  { state: RootState }
>(
  'marketplaceSolana/depositToEPA',
  async ({ amount }: DepositToEPAParams, { getState, dispatch }) => {
    try {
      dispatch({
        type: 'marketplaceSolana/setDepositStatus',
        payload: 'loading',
      });
      const { solanaWalletAdapter, publicKey } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (!solanaConnector || !solanaWalletAdapter || !publicKey) {
        throw new Error('Failed to solana depositToEPA');
      }

      await solanaConnector.depositToEPA(solanaWalletAdapter, amount);

      dispatch({
        type: 'marketplaceSolana/setInputDeposit',
        payload: '',
      });
      dispatch({
        type: 'marketplaceSolana/setDepositStatus',
        payload: 'success',
      });
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      dispatch({
        type: 'marketplaceSolana/setDepositStatus',
        payload: 'error',
      });
    }
  },
);

export const withdrawFromEPA = createAsyncThunk<
  any | null,
  WithdrawFromEPAParams,
  { state: RootState }
>(
  'marketplaceSolana/withdrawFromEPA',
  async ({ amount }: WithdrawFromEPAParams, { getState, dispatch }) => {
    try {
      dispatch({
        type: 'marketplaceSolana/setWithdrawStatus',
        payload: 'loading',
      });
      const { solanaWalletAdapter, publicKey, balance } = getState().wallet;
      const { solanaConnector } = getState().marketplaceSolana;

      if (
        !solanaConnector ||
        !solanaWalletAdapter ||
        !publicKey ||
        !balance ||
        !balance.escrowSol
      ) {
        throw new Error('Failed to solana withdrawFromEPA');
      }

      const checkEscrowSol = +balance.escrowSol - amount;

      if (checkEscrowSol < escrowWalletMinSol) {
        await solanaConnector.closeEPA(solanaWalletAdapter);
      } else {
        await solanaConnector.withdrawFromEPA(solanaWalletAdapter, amount);
      }

      dispatch({
        type: 'marketplaceSolana/setInputWithdraw',
        payload: '',
      });
      dispatch({
        type: 'marketplaceSolana/setWithdrawStatus',
        payload: 'success',
      });
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.error(err);
      dispatch({
        type: 'marketplaceSolana/setWithdrawStatus',
        payload: 'error',
      });
    }
  },
);

const initialState: State = {
  solanaConnector: null,
  isConnectorLoading: false,
  txStatus: null,
  transactionHash: null,
  currentRequestId: undefined,
  depositStatus: 'nothing',
  inputDeposit: '',
  isDepositBoxOpen: false,
  withdrawStatus: 'nothing',
  inputWithdraw: '',
  isWithdrawBoxOpen: false,
};

const marketplaceSolana = createSlice({
  name: 'marketplaceSolana',
  initialState,
  reducers: {
    setTxStatus: (state, action) => {
      state.txStatus = action.payload;
    },
    setTxStatusWithHash: (state, action) => {
      const { status, transactionHash } = action.payload;
      state.txStatus = status;
      state.transactionHash = transactionHash;
    },
    clearTxStatus: (state) => {
      state.txStatus = null;
    },
    successTxStatus: (state) => {
      state.txStatus = 'TX_SUCCESS';
    },
    setDepositStatus: (state, action) => {
      state.depositStatus = action.payload;
    },
    setInputDeposit: (state, action) => {
      state.inputDeposit = action.payload;
    },
    setIsDepositBoxOpen: (state, action) => {
      state.isDepositBoxOpen = action.payload;
    },
    setWithdrawStatus: (state, action) => {
      state.withdrawStatus = action.payload;
    },
    setInputWithdraw: (state, action) => {
      state.inputWithdraw = action.payload;
    },
    setIsWithdrawBoxOpen: (state, action) => {
      state.isWithdrawBoxOpen = action.payload;
    },
    resetConnector: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(setConnector.pending, (state, action) => {
      state.isConnectorLoading = true;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(setConnector.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.solanaConnector = action.payload;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(setConnector.rejected, (state) => {
      state.isConnectorLoading = false;
      state.currentRequestId = undefined;
    });
    builder.addCase(createSellOrder.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(createBuyOrder.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fulfillFixedPriceSale.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(cancelBuyOrder.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(cancelSellOrder.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fulfillBuyOffer.rejected, (state) => {
      if (state.txStatus !== 'TX_DENIED') {
        state.txStatus = 'TX_FAILED';
        state.currentRequestId = undefined;
      }
    });
  },
});

export const {
  resetConnector,
  clearTxStatus,
  setTxStatusWithHash,
  setDepositStatus,
  setInputDeposit,
  setIsDepositBoxOpen,
  setWithdrawStatus,
  setInputWithdraw,
  setIsWithdrawBoxOpen,
} = marketplaceSolana.actions;

export default marketplaceSolana.reducer;
