export const badWords = [
  'ahole',
  'anus',
  'ash0le',
  'ash0les',
  'asholes',
  'ass',
  'Ass Monkey',
  'Assface',
  'assh0le',
  'assh0lez',
  'asshole',
  'assholes',
  'assholz',
  'asswipe',
  'azzhole',
  'bassterds',
  'bastard',
  'bastards',
  'bastardz',
  'basterds',
  'basterdz',
  'Biatch',
  'bitch',
  'bitches',
  'Blow Job',
  'boffing',
  'butthole',
  'buttwipe',
  'c0ck',
  'c0cks',
  'c0k',
  'Carpet Muncher',
  'cawk',
  'cawks',
  'Clit',
  'cnts',
  'cntz',
  'cock',
  'cockhead',
  'cock-head',
  'cocks',
  'CockSucker',
  'cock-sucker',
  'crap',
  'cum',
  'cunt',
  'cunts',
  'cuntz',
  'dick',
  'dild0',
  'dild0s',
  'dildo',
  'dildos',
  'dilld0',
  'dilld0s',
  'dominatricks',
  'dominatrics',
  'dominatrix',
  'dyke',
  'enema',
  'f u c k',
  'f u c k e r',
  'fag',
  'fag1t',
  'faget',
  'fagg1t',
  'faggit',
  'faggot',
  'fagg0t',
  'fagit',
  'fags',
  'fagz',
  'faig',
  'faigs',
  'fart',
  'flipping the bird',
  'fuck',
  'fucker',
  'fuckin',
  'fucking',
  'fucks',
  'Fudge Packer',
  'fuk',
  'Fukah',
  'Fuken',
  'fuker',
  'Fukin',
  'Fukk',
  'Fukkah',
  'Fukken',
  'Fukker',
  'Fukkin',
  'g00k',
  'God-damned',
  'h00r',
  'h0ar',
  'h0re',
  'hells',
  'hoar',
  'hoor',
  'hoore',
  'jackoff',
  'jap',
  'japs',
  'jerk-off',
  'jisim',
  'jiss',
  'jizm',
  'jizz',
  'knob',
  'knobs',
  'knobz',
  'kunt',
  'kunts',
  'kuntz',
  'Lezzian',
  'Lipshits',
  'Lipshitz',
  'masochist',
  'masokist',
  'massterbait',
  'masstrbait',
  'masstrbate',
  'masterbaiter',
  'masterbate',
  'masterbates',
  'Motha Fucker',
  'Motha Fuker',
  'Motha Fukkah',
  'Motha Fukker',
  'Mother Fucker',
  'Mother Fukah',
  'Mother Fuker',
  'Mother Fukkah',
  'Mother Fukker',
  'mother-fucker',
  'Mutha Fucker',
  'Mutha Fukah',
  'Mutha Fuker',
  'Mutha Fukkah',
  'Mutha Fukker',
  'n1gr',
  'nastt',
  'nigger;',
  'nigur;',
  'niiger;',
  'niigr;',
  'orafis',
  'orgasim;',
  'orgasm',
  'orgasum',
  'oriface',
  'orifice',
  'orifiss',
  'packi',
  'packie',
  'packy',
  'paki',
  'pakie',
  'paky',
  'pecker',
  'peeenus',
  'peeenusss',
  'peenus',
  'peinus',
  'pen1s',
  'penas',
  'penis',
  'penis-breath',
  'penus',
  'penuus',
  'Phuc',
  'Phuck',
  'Phuk',
  'Phuker',
  'Phukker',
  'polac',
  'polack',
  'polak',
  'Poonani',
  'pr1c',
  'pr1ck',
  'pr1k',
  'pusse',
  'pussee',
  'pussy',
  'puuke',
  'puuker',
  'qweir',
  'recktum',
  'rectum',
  'retard',
  'sadist',
  'scank',
  'schlong',
  'screwing',
  'semen',
  'sex',
  'sexy',
  'Sh!t',
  'sh1t',
  'sh1ter',
  'sh1ts',
  'sh1tter',
  'sh1tz',
  'shit',
  'shits',
  'shitter',
  'Shitty',
  'Shity',
  'shitz',
  'Shyt',
  'Shyte',
  'Shytty',
  'Shyty',
  'skanck',
  'skank',
  'skankee',
  'skankey',
  'skanks',
  'Skanky',
  'slag',
  'slut',
  'sluts',
  'Slutty',
  'slutz',
  'son-of-a-bitch',
  'tit',
  'turd',
  'va1jina',
  'vag1na',
  'vagiina',
  'vagina',
  'vaj1na',
  'vajina',
  'vullva',
  'vulva',
  'w0p',
  'wh00r',
  'wh0re',
  'whore',
  'xrated',
  'xxx',
  'b!+ch',
  'bitch',
  'blowjob',
  'clit',
  'arschloch',
  'fuck',
  'shit',
  'ass',
  'asshole',
  'b!tch',
  'b17ch',
  'b1tch',
  'bastard',
  'bi+ch',
  'boiolas',
  'buceta',
  'c0ck',
  'cawk',
  'chink',
  'cipa',
  'clits',
  'cock',
  'cum',
  'cunt',
  'dildo',
  'dirsa',
  'ejakulate',
  'fatass',
  'fcuk',
  'fuk',
  'fux0r',
  'hoer',
  'hore',
  'jism',
  'kawk',
  'l3itch',
  'l3i+ch',
  'masturbate',
  'masterbat*',
  'masterbat3',
  'motherfucker',
  's.o.b.',
  'mofo',
  'nazi',
  'nigga',
  'nigger',
  'nutsack',
  'phuck',
  'pimpis',
  'pusse',
  'pussy',
  'scrotum',
  'sh!t',
  'shemale',
  'shi+',
  'sh!+',
  'slut',
  'smut',
  'teets',
  'tits',
  'boobs',
  'b00bs',
  'teez',
  'testical',
  'testicle',
  'titt',
  'w00se',
  'jackoff',
  'wank',
  'whoar',
  'whore',
  '*damn',
  '*dyke',
  '*fuck*',
  '*shit*',
  '@$$',
  'amcik',
  'andskota',
  'arse*',
  'assrammer',
  'ayir',
  'bi7ch',
  'bitch*',
  'bollock*',
  'breasts',
  'butt-pirate',
  'cabron',
  'cazzo',
  'chraa',
  'chuj',
  'Cock*',
  'cunt*',
  'd4mn',
  'daygo',
  'dego',
  'dick*',
  'dike*',
  'dupa',
  'dziwka',
  'ejackulate',
  'Ekrem*',
  'Ekto',
  'enculer',
  'faen',
  'fag*',
  'fanculo',
  'fanny',
  'feces',
  'feg',
  'Felcher',
  'ficken',
  'fitt*',
  'Flikker',
  'foreskin',
  'Fotze',
  'Fu(*',
  'fuk*',
  'futkretzn',
  'gook',
  'guiena',
  'h0r',
  'h4x0r',
  'hell',
  'helvete',
  'hoer*',
  'honkey',
  'Huevon',
  'hui',
  'injun',
  'jizz',
  'kanker*',
  'kike',
  'klootzak',
  'kraut',
  'knulle',
  'kuk',
  'kuksuger',
  'Kurac',
  'kurwa',
  'kusi*',
  'kyrpa*',
  'lesbo',
  'mamhoon',
  'masturbat*',
  'merd*',
  'mibun',
  'monkleigh',
  'mouliewop',
  'muie',
  'mulkku',
  'muschi',
  'nazis',
  'nepesaurio',
  'nigger*',
  'orospu',
  'paska*',
  'perse',
  'picka',
  'pierdol*',
  'pillu*',
  'pimmel',
  'piss*',
  'pizda',
  'poontsee',
  'poop',
  'porn',
  'p0rn',
  'pr0n',
  'preteen',
  'pula',
  'pule',
  'puta',
  'puto',
  'qahbeh',
  'queef*',
  'rautenberg',
  'schaffer',
  'scheiss*',
  'schlampe',
  'schmuck',
  'screw',
  'sh!t*',
  'sharmuta',
  'sharmute',
  'shipal',
  'shiz',
  'skribz',
  'skurwysyn',
  'sphencter',
  'spic',
  'spierdalaj',
  'splooge',
  'suka',
  'b00b*',
  'testicle*',
  'titt*',
  'twat',
  'vittu',
  'wank*',
  'wetback*',
  'wichser',
  'wop*',
  'yed',
  'zabourah',
];

export const signMessage = (account: string) =>
  `Welcome!\n\nClick "Sign" to sign in with your wallet.\n\nThis signing request involves a safety measure to identify and prevent unknown signees to interfere with your wallet log-in and related user activities while using the service.\n\nYou are signing in with a wallet address of: ${account}`;

export const walletName = {
  metamask: 'MetaMask',
  portis: 'Portis',
  walletlink: 'Coinbase Wallet',
  walletconnect: 'WalletConnect',
  torus: 'Torus Wallet',
  phantom: 'Phantom',
  glow: 'Glow',
  kaikas: 'Kaikas',
};

export const stakingMessage = {
  staking: 'Staking was successful!',
  unstaking: 'Unstaking was successful!',
  approved: 'Access allowed!',
};
