import { useMemo, useState } from 'react';
import NextImage from 'next/image';
import type { ImageProps } from 'next/image';

export type Props = Omit<ImageProps, 'src'> & {
  /** 이미지가 저장된 위치를 지정합니다 */
  prefix?: 'local' | 's3';
  src: string;
};

const imageHost = {
  s3: `https://images.playdapp.com/marketplace${
    process.env.NEXT_PUBLIC_ENV === 'development' ? '-dev' : ''
  }`,
  local: '/assets/images',
};

const errorImage =
  'https://images.playdapp.com/marketplace-dev/collections/placeholder2v.png';

const Image = ({ prefix, src, ...rest }: Props) => {
  const [isError, setIsError] = useState(false);
  const imageUrl = useMemo(
    () => (prefix ? `${imageHost[prefix]}${src}` : src),
    [prefix, src],
  );

  if (
    prefix !== 'local' &&
    !/http(s?):\/\/images.playdapp.com\//.test(imageUrl)
  ) {
    return (
      <img
        src={isError ? errorImage : imageUrl}
        draggable={false}
        width={rest.width}
        height={rest.height}
        alt={rest.alt || ''}
        onError={() => setIsError(true)}
      />
    );
  }

  return (
    <NextImage
      src={isError ? errorImage : imageUrl}
      layout="fixed"
      draggable={false}
      {...rest}
      onError={() => setIsError(true)}
    />
  );
};

export default Image;
