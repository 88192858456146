import styled from '@emotion/styled';
import { breakpoints, palette, Progress, spacing } from '@playdapp/ui';

import type { MessageType } from 'types/event';

import Icon, { Icons } from '@/components/Icon';

type Props = {
  allowClose: boolean;
  type: MessageType;
  children: React.ReactNode;
  onClose: () => void;
};

const colors = {
  loading: { bgColor: 'rgba(239, 239, 242, 0.9)', borderColor: '#D0D1D7' },
  success: { bgColor: 'rgba(235, 249, 247, 0.9)', borderColor: '#2BCAB0' },
  warning: { bgColor: 'rgba(255, 252, 230, 0.9)', borderColor: '#E8BA11' },
  error: { bgColor: 'rgba(255, 240, 241, 0.9)', borderColor: '#F6515F' },
};

const ToastBox = styled.div<{ bgColor: string; borderColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.s} ${spacing.m};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${palette.dgray600};
  background-color: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 0.75rem;
  box-shadow: 0px 4px 8px rgba(30, 31, 36, 0.15);
  backdrop-filter: blur(2px);
  gap: 1rem;

  ${breakpoints.down('sm')} {
    align-items: flex-start;
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Toast = ({ type, allowClose, onClose, children }: Props) => (
  <ToastBox
    bgColor={colors[type].bgColor}
    borderColor={colors[type].borderColor}
  >
    {type !== 'loading' ? (
      <Icon name={`toast-${type}` as Icons} width={20} height={20} />
    ) : (
      <Progress size="small" color={palette.gray800} />
    )}
    {children}
    {allowClose && (
      <Button type="button" onClick={onClose}>
        <Icon name={`toast-close-${type}` as Icons} size={12} />
      </Button>
    )}
  </ToastBox>
);

export default Toast;
