import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';

import type { DropsItem, ResponsePageStatus } from 'types/drops';

import { getDropsList } from 'api/drops';
import DROPS_TOKEN from 'data/contracts/PlaydappDrops.json';
import { CollectionResponseData } from 'types/collections';

type State = {
  response: DropsItem[];
  isLoading: boolean;
  isError: boolean;
  selectedDropsListCollection: CollectionResponseData | null;
};

type GetDropsListResult = {
  response: DropsItem[];
};

type GetDropsParam = {
  library: Web3Provider | null;
};

type FetchCheckTotalSupplyParam = GetDropsParam & {
  contractAddress: string;
};

const initialState: State = {
  response: [],
  isLoading: true,
  isError: false,
  selectedDropsListCollection: null,
};

const fetchCheckTotalSupply = async ({
  library,
  contractAddress,
}: FetchCheckTotalSupplyParam): Promise<ResponsePageStatus> => {
  if (!library || !contractAddress) return 'sold';

  const dropsContract = new Contract(
    contractAddress,
    DROPS_TOKEN.abi,
    library.getSigner(),
  );

  const result = await dropsContract.checkTotalSupply();

  return result ? 'sell' : 'sold';
};

export const getDrops = createAsyncThunk<GetDropsListResult, GetDropsParam>(
  'drops/getDrops',
  async ({ library }: GetDropsParam, { rejectWithValue }) => {
    try {
      const response = await getDropsList();

      if (!response || response.status !== 200 || !response.data) {
        throw new Error('Failed to Drops API');
      }

      if (response.data && library) {
        const newResponseData = await Promise.all(
          response.data.map(async (item) => {
            if (item.status !== 'sell' || !item.contractAddress) return item;

            const status = await fetchCheckTotalSupply({
              library,
              contractAddress: item.contractAddress,
            });

            return {
              ...item,
              status,
            };
          }),
        );

        return {
          response: newResponseData,
        };
      }

      return {
        response: response.data,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const dropsSlice = createSlice({
  name: 'dropsList',
  initialState,
  reducers: {
    setSelectedDropsListCollection: (
      state,
      action: PayloadAction<CollectionResponseData | null>,
    ) => {
      state.selectedDropsListCollection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDrops.pending, (state) => {
      state.response = [];
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getDrops.fulfilled, (state, action) => {
      state.response = action.payload.response;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(getDrops.rejected, (state) => {
      state.response = [];
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { setSelectedDropsListCollection } = dropsSlice.actions;

export default dropsSlice.reducer;
