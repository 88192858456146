import { useMemo, useCallback } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import type {
  ConnectionContextState,
  WalletContextState,
} from '@solana/wallet-adapter-react';

import type { SolanaChainId } from 'types/network';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setBalanceLoading, setBalanceError, setBalance } from 'store/wallet';
import { network } from 'data/marketplace';

type Return = ConnectionContextState &
  WalletContextState & {
    chainId: SolanaChainId | null;
    updateBalance: () => Promise<void>;
  };

const useSolana = (): Return => {
  const walletState = useWallet();
  const connectionState = useConnection();
  const dispatch = useAppDispatch();

  const { solanaConnector } = useAppSelector(
    ({ marketplaceSolana }) => marketplaceSolana,
  );

  const { balance } = useAppSelector(({ wallet }) => wallet);

  const chainId = useMemo(() => {
    if (!network) return null;
    return network;
  }, [network]);

  const updateBalance = useCallback(async () => {
    if (
      walletState.connected &&
      walletState.wallet &&
      connectionState.connection &&
      walletState.publicKey &&
      solanaConnector
    ) {
      try {
        if (!balance) {
          dispatch(setBalanceError(false));
          dispatch(setBalanceLoading(true));
        }

        const mainWalletBalance = await connectionState.connection.getBalance(
          walletState.publicKey,
        );

        const escrowWalletBalance = await solanaConnector.getEPABalance(
          walletState.wallet.adapter,
          walletState.publicKey.toString(),
        );

        const basisPoints = escrowWalletBalance.basisPoints.toNumber();

        dispatch(
          setBalance({
            sol: mainWalletBalance / LAMPORTS_PER_SOL,
            escrowSol: basisPoints / LAMPORTS_PER_SOL,
          }),
        );
      } catch (e) {
        dispatch(setBalance(null));
        dispatch(setBalanceError(true));
      } finally {
        dispatch(setBalanceLoading(false));
      }
    }
  }, [
    dispatch,
    walletState.connected,
    connectionState.connection,
    walletState.publicKey,
    solanaConnector,
  ]);

  return {
    ...connectionState,
    ...walletState,
    chainId,
    updateBalance,
  };
};

export default useSolana;
