import styled from '@emotion/styled';
import { fontStyle, palette } from '@playdapp/ui';

import Image from '@/components/Image';

const ContainerBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  p {
    color: ${palette.dpri500};
    text-align: center;
    ${fontStyle('h3')};
  }
`;

const ImageBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const NotAvailable = () => (
    <ContainerBlock>
      <ImageBlock>
        <Image
          prefix="s3"
          src="/etc/not-available.png"
          width={298}
          height={298}
        />
      </ImageBlock>
      <p>Sorry. PlayDapp Market is not available in your country.</p>
    </ContainerBlock>
  );

export default NotAvailable;
