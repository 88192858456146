type ContractAddressType = {
  [x: string]: any;
};

const contractAddress: ContractAddressType = {
  token: {
    weth: {
      1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      11155111: '0x5279C74ea8791aAAcAE23a2D8548c87992efd979',
      137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      80002: '0x8A0A41136138CED0496DBb7c30F57212D452C68c',
    },
    pla: {
      1: '0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430',
      11155111: '0x8E8003Df2587243bB0ce25221c2fa5a217161eF1',
      137: '0x8765f05ADce126d70bcdF1b0a48Db573316662eB',
      80002: '0xFb61c22a372Dea97EC52362187dB4394c98a7AcD',
    },
    matic: {
      1: '0x0000000000000000000000000000000000000000',
      11155111: '0x0000000000000000000000000000000000000000',
      137: '0x0000000000000000000000000000000000000000',
      80002: '0x0000000000000000000000000000000000000000',
    },
    eth: {
      1: '0x0000000000000000000000000000000000000000',
      11155111: '0x0000000000000000000000000000000000000000',
      137: '0x0000000000000000000000000000000000000000',
      80002: '0x0000000000000000000000000000000000000000',
    },
    klay: {
      1001: '0x0000000000000000000000000000000000000000',
      8217: '0x0000000000000000000000000000000000000000',
    },
    wklay: {
      1001: '0x043c471bee060e00a56ccd02c0ca286808a5a436',
      8217: '0x19aac5f612f524b754ca7e7c41cbfa2e981a4432',
    },
    playzV1: {
      137: '0x8494d2ed08c85A6B9014fB298f4373F2e5911b87',
      80002: '0x46Abb798189194f0cCA596F8B8C49172267c59E0',
    },
    playzV2: {
      137: '0xc58C5A1c29fcC53e3041D5f889dEA9cE683B3a3C',
      80002: '0x2b5aE4f7a7e8A03AbaC05A6C3893D778972a5F81',
    },
    playdappDragon: {
      137: '0x047c2fAB5E150b5c47bF636FDD2F87E462F42D11',
      80002: '0xbAc404f82950D834dbd27d39bAEE552C7A9A2867',
    },
    playdapp: {
      137: '0xc9C9e9Acd4dEE1099060f0360F852b1AAe896447',
      80002: '0xd07d2f04073af497AC4952d43b8FBC12269e3336',
    },
    claim: {
      137: '0xB0E8a27682cA2CC4C4086F50386391E7c589d078',
      80002: '0xEd749965B690C58E6eB705311eBfaB529832b058',
    },
    awtgLuckyDraw: {
      137: '0x0CbDD41C9D6F9506538206841a55C13f72FD4222',
      80002: '0xfA89c41d94b327E9c36a7278484d55e539642082',
    },
  },
  geodomainPayment: {
    137: '0xaCf20a217Bfa91a6531cFE04861bE58E2a644C31',
    80002: '0x1e2ff6094733a4cdc0e26F916d20f39f5698B18c',
  },
  awtgLucky: {
    137: '0x96A20d21861aa775294eBC507A72ECaD5e438881',
    80002: '0x72D5648485Fa5182C58A81F61bb21EE9a19082DC',
  },
  dropsPlayzSsr: {
    137: '0xE501BD0E7E4bb86d28F793e91aDFe5B6d0093d99',
    80002: '0x0f810B04868EED14aa3E0BCBB12C9b90889313cB',
  },
  dropsProxy: {
    137: '0x231B0D272D5FF3a9B47D971FB6b1CAc2F149a8ae',
    80002: '0x02148F10EA5cFc9474Afadeb48877500803a138a',
  },
};

export default contractAddress;
