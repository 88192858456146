import { Klay } from 'caver-js';

export default async function getKlayBalance(lib: Klay, account: string) {
  try {
    const balance = await lib.getBalance(account);
    return +balance.toString();
  } catch {
    return -1;
  }
}
