import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

export const actionSetTxStatus = 'marketplace/setTxStatus';
export const actionSetTxStatusWithHash = 'marketplace/setTxStatusWithHash';
export const escrowWalletMinSol = 0.001;
export const network =
  process.env.NEXT_PUBLIC_ENV === 'development'
    ? WalletAdapterNetwork.Devnet
    : WalletAdapterNetwork.Mainnet;
export default null;
