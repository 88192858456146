import styled from '@emotion/styled';
import { breakpoints, fontSize, Modal, palette, spacing } from '@playdapp/ui';

import { useAppDispatch } from 'store/hooks';
import { resetState } from 'store/merge';

import Button from '@/components/Button';
import Icon from '@/components/Icon';

type Props = {
  isOpen: boolean;
  isOwnerCheckFail?: boolean;
  textList: string[];
  isShowIcon?: boolean;
  handleOpen: (isOpen: boolean) => void;
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleBlock = styled.div<{ isOwnerCheckFail?: boolean }>`
  margin-top: ${spacing.xl};
  text-align: center;

  span {
    font-size: ${fontSize.h3};
    font-weight: 800;
    color: ${palette.black};
  }

  ${breakpoints.down('md')} {
    margin-top: ${spacing.l};
  }

  ${({ isOwnerCheckFail }) =>
    isOwnerCheckFail &&
    `
    margin-top: 0px;

    span {
      font-size: ${fontSize.h5};
    }
  `}
`;

const DescBlock = styled.div`
  margin-top: ${spacing.s};
  text-align: center;

  p {
    color: ${palette.gray900};
  }
`;

const CustomButton = styled(Button)`
  width: 100%;
  margin-top: ${spacing.xl};

  ${breakpoints.down('md')} {
    margin-top: ${spacing.l};
  }
`;

const MergeErrorModal = ({
  isOpen,
  isOwnerCheckFail = false,
  textList,
  isShowIcon = true,
  handleOpen,
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      width={{ lg: '500px', xl: '500px' }}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      handleOpen={() => {
        dispatch(resetState());
        handleOpen(false);
      }}
    >
      <ModalContent>
        {isShowIcon && <Icon name="tx-error" width="80px" height="71.13px" />}
        <TitleBlock isOwnerCheckFail={isOwnerCheckFail}>
          <span>
            {isOwnerCheckFail
              ? 'NFT’s Ownership Validation in Progress'
              : 'Error'}
          </span>
        </TitleBlock>
        <DescBlock>
          {textList.map((text, idx) => (
            <p key={`merge-error-modal-text-${idx}`}>{text}</p>
          ))}
        </DescBlock>
        <CustomButton
          onClick={() => {
            dispatch(resetState());
            handleOpen(false);
          }}
        >
          OK
        </CustomButton>
      </ModalContent>
    </Modal>
  );
};

export default MergeErrorModal;
