import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import type { AbstractConnector } from '@web3-react/abstract-connector';
import type { NetworkName } from 'types/network';
import type { WalletProvider } from 'types/wallet';

import {
  fetchUserInfo,
  fetchUserProfile,
  setUserLoading,
  resetUserInfo,
} from 'store/user';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPageNetwork } from 'store/page';
import { getBalances, setWalletInfo, setWalletLoading } from 'store/wallet';
import { removeLoginInfo } from 'lib/auth';
import { connectorsByNetwork } from 'lib/connectors';
import { activeChainId } from 'lib/network';
import useSolana from 'hooks/useSolana';
import useToastMessage from 'hooks/useToastMessage';

const useLoadStorage = () => {
  const router = useRouter();
  const { activate, deactivate, active, account, library, chainId } =
    useWeb3React<Web3Provider>();
  const {
    publicKey,
    connected,
    connecting,
    connection,
    chainId: solanaChainId,
    wallet: solanaWallet,
    disconnect,
    updateBalance,
  } = useSolana();

  const { handleUpdateToast, handleCloseToast } = useToastMessage({
    position: 'top',
    duration: 5000,
  });

  const {
    isUserLoading: isLoading,
    isError: isUserError,
    errorMessage: userErrorMessage,
  } = useAppSelector(({ user }) => user);

  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    removeLoginInfo();
    deactivate();
    disconnect();
    dispatch(setPageNetwork(null));

    if (isUserError) {
      const nextErrorMessage =
        userErrorMessage === 'Invalid Authorization Token'
          ? 'Wallet Authentication Session has expired.'
          : userErrorMessage;
      handleUpdateToast(nextErrorMessage, 'error', true);
      dispatch(resetUserInfo());
      router.push('/');
    }
  }, [dispatch, isUserError, userErrorMessage]);

  const connectWallet = useCallback(() => {
    const provider = localStorage.getItem(
      'PDMP_WALLET_PROVIDER',
    ) as WalletProvider;
    const network = localStorage.getItem('PDMP_NETWORK') as NetworkName;

    if (
      !isUserError &&
      provider &&
      network &&
      network !== 'solana' &&
      provider !== 'glow' &&
      provider !== 'phantom' &&
      provider !== 'walletconnect'
    ) {
      (async () => {
        dispatch(setWalletLoading(true));
        try {
          await activate(
            connectorsByNetwork[network][provider] as AbstractConnector,
            () => {
              handleLogout();
              dispatch(setWalletLoading(false));
              dispatch(setUserLoading(false));
            },
            true,
          );
        } catch {
          handleLogout();
          dispatch(setWalletLoading(false));
          dispatch(setUserLoading(false));
        }
      })();
    } else if ((!provider && !network) || provider === 'walletconnect') {
      handleLogout();
      dispatch(setWalletLoading(false));
      dispatch(setUserLoading(false));
    }
  }, [activate, handleLogout, dispatch, connecting, disconnect, isUserError]);

  const reconnectWallet = useCallback(() => {
    if (!isUserError && isLoading) {
      connectWallet();
    }
  }, [isLoading, connectWallet]);

  useEffect(() => {
    connectWallet();
  }, [connectWallet]);

  useEffect(() => {
    router.events.on('routeChangeComplete', reconnectWallet);
    return () => {
      router.events.off('routeChangeComplete', reconnectWallet);
    };
  }, [router.events, reconnectWallet]);

  useEffect(() => {
    const provider = localStorage.getItem(
      'PDMP_WALLET_PROVIDER',
    ) as WalletProvider;
    const network = localStorage.getItem('PDMP_NETWORK') as NetworkName;

    if (!provider || !network) return;

    if (!isUserError && isLoading && active && account && library && chainId) {
      dispatch(
        setWalletInfo({
          account,
          provider,
          activeChainId: activeChainId[network],
          library,
          chainId,
        }),
      );
      dispatch(getBalances({ account, library, chainId }));
      dispatch(fetchUserInfo({ account }));
      dispatch(fetchUserProfile({ account }));
    }

    if (!isUserError && isLoading && connected && !!publicKey) {
      updateBalance();
      dispatch(
        setWalletInfo({
          account: publicKey.toString(),
          provider,
          activeChainId: activeChainId[network],
          publicKey,
          connection,
          chainId: solanaChainId,
          solanaWalletAdapter: solanaWallet?.adapter,
        }),
      );
      dispatch(fetchUserInfo({ account: publicKey.toString() }));
    }
  }, [
    isLoading,
    isUserError,
    active,
    account,
    library,
    chainId,
    connected,
    publicKey,
    dispatch,
    updateBalance,
  ]);

  useEffect(() => {
    if (isUserError) {
      handleLogout();
    }
  }, [isUserError, handleLogout]);

  useEffect(
    () => () => {
      handleCloseToast();
    },
    [],
  );
};

export default useLoadStorage;
