import styled from '@emotion/styled';
import { breakpoints, fontSize, Modal, palette, spacing } from '@playdapp/ui';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetState } from 'store/merge';
import { MergeSuccessData } from 'types/merge';

import Button from '@/components/Button';
import Image from '@/components/Image';
import Redirect from '@/components/Redirect';

type Props = {
  isOpen: boolean;
  mergeSuccessData: MergeSuccessData | null;
  handleOpen: (isOpen: boolean) => void;
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageBlock = styled.div`
  img {
    width: 100%;
    max-width: 200px;
  }

  ${breakpoints.down('md')} {
    img {
      max-width: 120px;
    }
  }
`;

const TitleBlock = styled.div`
  margin-top: ${spacing.xl};

  span {
    font-size: ${fontSize.h3};
    font-weight: 800;
    color: ${palette.black};
  }

  ${breakpoints.down('md')} {
    margin-top: ${spacing.l};
  }
`;

const DescBlock = styled.div`
  margin-top: ${spacing.s};
  text-align: center;

  span {
    color: ${palette.gray900};
  }

  span.bold {
    font-weight: 600;
  }
`;

const LinkBlock = styled.div`
  margin-top: ${spacing.s};

  a {
    color: ${palette.blue600};
    font-size: ${fontSize.p5};
    font-weight: 600;
  }

  ${breakpoints.down('md')} {
    margin-top: ${spacing.xs};
  }
`;

const CustomButton = styled(Button)`
  width: 100%;
  margin-top: ${spacing.xl};

  ${breakpoints.down('md')} {
    margin-top: ${spacing.l};
  }
`;

const MergeSuccessModal = ({ isOpen, mergeSuccessData, handleOpen }: Props) => {
  const { activeChainId } = useAppSelector(({ wallet }) => wallet);
  const dispatch = useAppDispatch();

  const polygonScanUrl =
    activeChainId && activeChainId === 137
      ? 'https://polygonscan.com'
      : 'https://www.oklink.com/amoy';

  const link = mergeSuccessData
    ? `${polygonScanUrl}/tx/${mergeSuccessData.txHashVRF}`
    : '';

  return (
    <Modal
      width={{ lg: '500px', xl: '500px' }}
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      handleOpen={() => {
        dispatch(resetState());
        handleOpen(false);
      }}
    >
      <ModalContent>
        <ImageBlock>
          {mergeSuccessData && <Image src={mergeSuccessData.image} alt="" />}
        </ImageBlock>
        <TitleBlock>
          <span>Merge Successful!</span>
        </TitleBlock>
        <DescBlock>
          <span>
            Your{' '}
            {mergeSuccessData?.name && (
              <span className="bold">[{mergeSuccessData.name}]</span>
            )}{' '}
            is created and is now in your wallet.
          </span>
        </DescBlock>
        <LinkBlock>
          <Redirect to={link}>For VRF check, visit the following link</Redirect>
        </LinkBlock>
        <CustomButton
          onClick={() => {
            dispatch(resetState());
            handleOpen(false);
          }}
        >
          OK
        </CustomButton>
      </ModalContent>
    </Modal>
  );
};

export default MergeSuccessModal;
