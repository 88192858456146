/* eslint-disable prefer-template */
/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-destructuring */
import { badWords } from 'data/word';
import TimeRemain from 'types/TimeRemain';
import { NetworkName } from 'types/network';

import contractAddress from './web3/contract-address';
import { activeChainId } from './network';

/**
 * @description Emotion css props으로 스타일링한 element 검색
 * @param container 대상 컨테이너
 * @param tagName 태그 이름
 * @param cssName prop으로 지정된 css 객체 name
 * @returns 검색한 첫번째 Element 반환, 검색 결과가 없으면 undefined 반환
 */
export const getElementToEmotionCssName = (
  container: HTMLElement,
  tagName = 'div',
  cssName: string,
): Element | undefined =>
  Array.from(container.getElementsByTagName(tagName)).find((element) =>
    element.className.match(new RegExp(cssName)),
  );

/**
 * @description 주소길이 줄이기
 * @param account wallet account address
 * @param startIndex
 * @param endIndex
 */
export const convertAddress = (
  account: string,
  startIndex?: number,
  endIndex?: number,
): string =>
  `${account.substring(0, startIndex || 10)}...${account.substring(
    endIndex || 32,
  )}`;

/**
 * @description sign message 생성
 * @param message message
 */
export const getSignMessage = (message: string): string =>
  `0x${Buffer.from(message).toString('hex')}`;

/**
 * @description breakpoint의 미디어 쿼리 조건만 반환합니다(useMedia에서 사용)
 * @param mediaQuery 미디어 쿼리
 */
// eslint-disable-next-line arrow-body-style
export const getBreakpointQuery = (mediaQuery: string): string => {
  return mediaQuery.replace(/@media /, '');
};

/**
 * @description 문자열을 집어넣으면 해당 문자열의 모든 단어를 PascalCase로 변환합니다.
 * @param str 변환할 문자열
 */
export const toPascalCase = (str: string): string =>
  // eslint-disable-next-line arrow-body-style
  str.replace(/\w+/g, (word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  });

/**
 * @description 오늘 날짜를 기준으로 넘겨받은 날짜(timestamp)까지 얼마나 남았는지 계산하여 return 해줍니다.
 * @param timestamp 날짜 timestamp 값 ex) 1646213887000
 */
export const getTimeRemain = (timestamp: number | string): TimeRemain => {
  const today = new Date();
  const endDay = new Date(timestamp);

  const distance = endDay.getTime() - today.getTime();

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

/**
 * @description 입력한 가격(price)에 수수료는 얼마인지 계산하여 리턴해줍니다.
 * @param price 가격
 * @param feePercent 수수료 ex) 0.025
 */
export const getFeePrice = (price: string, feePercent: number): number =>
  price ? parseFloat((Number(price) * feePercent).toFixed(4)) : 0;

/**
 * @description bid, sell 등 price 입력할 때 사용되는 유효성 검사
 * @param value 입력한 가격
 * @param balance 소지금
 * @param max 최대 입력 할 수 있는 가격
 */
export const handlePriceValidation = (
  value: string,
  balance: number,
  max: number,
): string => {
  try {
    if (!value) return '';

    const valid = value.search(/^\d*(\.?\d*)$/) !== -1;
    const countDot = value.split('').filter((w) => w === '.').length;

    if (value === '0.0000') return '';

    if (value.includes('/')) return value.replaceAll('/', '');

    if (value.startsWith('.')) return value.slice(1, value.length);

    // if (value.startsWith('0') && value.length > 1 && value.indexOf('.') !== 1) {
    //   if (value.length !== 2) return value.slice(1, value.length);

    //   return value.slice(0, value.length - 1);
    // }

    if (value.includes('..')) {
      return value.replaceAll('..', '.');
    }

    if (countDot > 1 && value.endsWith('.'))
      return value.slice(0, value.length - 1);

    if (countDot > 1) {
      const lastDot = value.lastIndexOf('.');
      const sliceText = value.slice(lastDot + 1, value.length);
      const result = `${value.slice(0, lastDot)}${sliceText}`;
      const [integer, points] = result.split('.');

      return `${integer}.${points.slice(0, 4)}`;
    }

    if (!valid) return value.replace(/[^\d/.]/g, '');

    if (Number(value) > max) return max.toString();

    if (Number(value) >= balance) return balance.toString();

    if (Number(value) >= 0 && Number(value) <= +balance) {
      const [integer, points] = value.split('.');
      if (!!points && points.length > 4) {
        return `${integer}.${points.slice(0, 4)}`;
      }

      return value;
    }

    return value;
  } catch (e) {
    console.log(e);
    return value;
  }
};

/**
 * bad words 검사
 */
export default function checkBadWord(word: string) {
  return (
    // eslint-disable-next-line arrow-body-style
    badWords.filter((badWord) => {
      return word.includes(badWord);
    }).length > 0
  );
}

/**
 * @description 번들이름 유효성 검사
 * @param value 입력 값
 */
export const handleBundleNameValidation = (value: string): boolean => {
  const nameReg = /^[A-Za-z0-9][A-Za-z0-9\s]{0,28}[A-Za-z0-9]{0,1}$/;
  const isValidName =
    nameReg.test(value) && !/\s{2,}/.test(value) && !/\s$/.test(value);
  const isBadWord = checkBadWord(value.replace(/\s/g, ''));

  const isValid = isValidName && !isBadWord;

  return isValid;
};

/**
 * @description network, currency에 맞는 토큰 주소를 리턴해줍니다.
 * @param network
 * @param currency
 */
export const getTokenAddress = (
  network: NetworkName,
  currency: string,
  // eslint-disable-next-line arrow-body-style
): string => {
  if (network === 'solana') return '';

  return contractAddress.token[currency][activeChainId[network]] || '';
};

/**
 * @description 넘겨 받은 날짜(date)에 며칠(days)을 더해서 리턴해줍니다.
 * @param date
 * @param days
 */
export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

/**
 * @description 넘겨받은 문자열을 PascalCase로 변환해줍니다.
 * @param value
 */
export const convertPascalCase = (value: string) =>
  value
    ? value.replace(/(\w)(\w*)/g, (_, t1, t2) => `${t1.toUpperCase()}${t2}`)
    : '';

/**
 * @description 1,000단위 콤마
 * @param num
 */
export const setComma = (num: number): string =>
  num.toLocaleString(undefined, { maximumFractionDigits: 4 });

/**
 * @description currency를 usd로 변환하는 함수
 * @param {Number} price
 * @param {Number} usd
 */
export const toUSD = (price: number, usd: number) =>
  (price * usd).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 *
 * @param {*} value
 * 숫자 크기에 따라 K, M 단위를 붙여줌
 */

export function mapNumberSuffix(value: string) {
  const parsedNumber = parseInt(value, 10);
  const result = Math.floor(parsedNumber / 1000);

  if (result < 1) return value;

  if (result < 1000) return `${result}K`;

  if (result < 1000000) return `${result / 1000}M`;

  return `${result / 1000000}B`;
}

/**
 * 모바일 디바이스 체크
 */
export const checkMobileDevice = () => {
  let check = false;
  (function checkUserAgent(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substring(0, 5),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

export const checkIos = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.indexOf('iphone') > -1 ||
    userAgent.indexOf('ipad') > -1 ||
    userAgent.indexOf('ipod') > -1
  );
};

/*
 * @desc X축 스크롤 이동 함수
 * @param {element} el overflow-x: scrool의 속성을 가진 부모 element
 * @param {element} target 중앙에 위치할 element
 * @param {number}  gab 여유공간(window의 inner-width의 중간 지점과 target element의 간격 차이)
 * @param {string}  behavior 스크롤 옵션
 */
export const setScrollToLeft = (
  el: HTMLElement,
  target: HTMLElement,
  behavior: ScrollBehavior = 'smooth',
) => {
  if (!el) return;

  if (!target) return;

  const gap = (window.innerWidth - target.offsetWidth) / 2;
  const left = target.offsetLeft - gap;

  el.scrollTo({
    top: 0,
    left,
    behavior,
  });
};

export const isBase58 = (value: string): boolean =>
  /^[A-HJ-NP-Za-km-z1-9]*$/.test(value);

/**
 * 숫자가 천 단위가 넘어가면 k, 만 단위가 넘어가면 m 등 숫자를 문자로 바꿔주는 함수
 */

export const intlFormat = (num: number) => {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
};

export const numberToAbbreviateString = (number: number) => {
  if (number >= 1000000) return intlFormat(number / 1000000) + 'M';
  if (number >= 1000) return intlFormat(number / 1000) + 'k';
  if (number === undefined) return '0';
  return intlFormat(number);
};

export const handleEncoding = (value: string, isEncode: boolean) => {
  if (isEncode) {
    const newValue = value
      .replaceAll('/', 'slash')
      .replaceAll('?', 'question')
      .replaceAll('=', 'equal')
      .replaceAll('.', 'dot')
      .replaceAll('&', 'and');
    return encodeURIComponent(newValue);
  }

  return decodeURIComponent(value)
    .replaceAll('slash', '/')
    .replaceAll('question', '?')
    .replaceAll('equal', '=')
    .replaceAll('dot', '.')
    .replaceAll('and', '&');
};

export const getDefaultCurrency = (network: NetworkName) => {
  if (network === 'polygon') return 'MATIC';
  if (network === 'ethereum') return 'ETH';
  if (network === 'klaytn') return 'KLAY';
  return 'SOL';
};
