/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import styled from '@emotion/styled';
import { palette, Progress, ColorName } from '@playdapp/ui';

import Color from 'styles/Color';
import Redirect from '@/components/Redirect';

type Size = 'large' | 'small';

type ButtonWrapProps = {
  children?: React.ReactNode;
  to?: string;
};

type Props = {
  color?: ColorName;
  size?: Size;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOutlined?: boolean;
  isTransparent?: boolean;
  isOnlyIcon?: boolean;
  type?: 'button' | 'submit';
  borderRadius?: string;
};

export type ButtonProps = ButtonWrapProps &
  Props &
  React.HTMLAttributes<HTMLButtonElement>;

const getBgColor = (
  color: ColorName,
  isDisabled: boolean,
  isLoading: boolean,
  isOutlined: boolean,
  isTransparent: boolean,
): string => {
  let result = palette[color];

  if (isDisabled && !isOutlined && !isTransparent) {
    result = Color(palette[color]).fade(0.5);
  } else if (isOutlined) {
    result = palette.white;
  } else if (isLoading && isTransparent) {
    result = palette.gray300;
  } else if (isTransparent) {
    result = 'inherit';
  }

  return result;
};

const getColor = (
  isOutlined: boolean,
  isDisabled: boolean,
  isTransparent: boolean,
): string => {
  let result = palette.white;

  if (!isDisabled && (isOutlined || isTransparent)) {
    result = palette.black;
  } else if (isDisabled && (isTransparent || isOutlined)) {
    result = Color(palette.black).fade(0.7);
  } else if (isDisabled && !isTransparent && !isOutlined) {
    result = Color(palette.white).fade(0.2);
  }

  return result;
};

const getHoverAndActiveBgColor = (
  action: 'active' | 'hover',
  color: ColorName,
  isDisabled: boolean,
  isLoading: boolean,
  isOutlined: boolean,
  isTransparent: boolean,
): string => {
  if (isDisabled || isLoading) return '';

  let result = Color(palette[color]).darker(action === 'hover' ? 5 : 10);

  if (isOutlined || isTransparent) {
    result = action === 'hover' ? palette.gray300 : palette.gray500;
  }

  return result;
};

const getCursor = (isDisabled: boolean, isLoading: boolean): string => {
  let result = 'pointer;';

  if (isDisabled) {
    result = 'not-allowed';
  } else if (isLoading) {
    result = 'wait';
  }
  return result;
};

const getBorder = (isOutlined: boolean, isDisabled: boolean): string => {
  if (isOutlined && !isDisabled) return `1px solid ${palette.black}`;
  if (isOutlined && isDisabled)
    return `1px solid ${Color(palette.black).fade(0.7)}`;
  return 'none';
};

const getPadding = (size: 'large' | 'small', isOnlyIcon: boolean): string => {
  let result = '0.75rem 2rem';

  if (size === 'small' && !isOnlyIcon) {
    result = '0.5rem 1.25rem';
  } else if (size === 'large' && isOnlyIcon) {
    result = '0.75rem';
  } else if (size === 'small' && isOnlyIcon) {
    result = '0.625rem';
  }

  return result;
};

const CustomButton = styled.button<Required<Props>>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ size, isOnlyIcon }) => getPadding(size, isOnlyIcon)};
  border-radius: ${({ borderRadius }) => borderRadius};
  font-weight: 600;
  cursor: ${({ isDisabled, isLoading }) => getCursor(isDisabled, isLoading)};
  font-size: ${({ theme, size }) => size === 'small' && `${theme.rem(14)}`};
  color: ${({ isOutlined, isDisabled, isTransparent }) =>
    getColor(isOutlined, isDisabled, isTransparent)};
  background-color: ${({
    color,
    isDisabled,
    isLoading,
    isOutlined,
    isTransparent,
  }) => getBgColor(color, isDisabled, isLoading, isOutlined, isTransparent)};
  border: ${({ isOutlined, isDisabled }) => getBorder(isOutlined, isDisabled)};
  transition: all 0.2s;
  outline: none;

  span {
    margin-right: ${({ isOnlyIcon }) => !isOnlyIcon && '0.25rem !important'};

    img {
      opacity: ${({ isDisabled }) => isDisabled && 0.5};
    }
  }

  &:hover {
    background-color: ${({
      color,
      isDisabled,
      isLoading,
      isOutlined,
      isTransparent,
    }) =>
      getHoverAndActiveBgColor(
        'hover',
        color,
        isDisabled,
        isLoading,
        isOutlined,
        isTransparent,
      )};
  }

  &:active {
    background-color: ${({
      color,
      isDisabled,
      isLoading,
      isOutlined,
      isTransparent,
    }) =>
      getHoverAndActiveBgColor(
        'active',
        color,
        isDisabled,
        isLoading,
        isOutlined,
        isTransparent,
      )};
  }
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = 'marketplace',
      size = 'large',
      to = '',
      isDisabled = false,
      isLoading = false,
      isOutlined = false,
      isTransparent = false,
      isOnlyIcon = false,
      borderRadius = '',
      type = 'button',
      ...rest
    },
    ref,
  ) => (
    <ButtonWrap to={to}>
      <CustomButton
        className={className}
        type={type}
        color={color}
        size={size}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isOutlined={isOutlined}
        isTransparent={isTransparent}
        isOnlyIcon={isOnlyIcon}
        disabled={isDisabled}
        borderRadius={borderRadius}
        ref={ref}
        {...rest}
      >
        {isLoading ? (
          <Progress
            color={isOutlined || isTransparent ? palette.black : palette.white}
            size="small"
          />
        ) : (
          <>{children}</>
        )}
      </CustomButton>
    </ButtonWrap>
  ),
);

const ButtonWrap = ({ children, to }: ButtonWrapProps) => {
  if (to) {
    return <Redirect to={to}>{children}</Redirect>;
  }

  return <>{children}</>;
};

export default Button;
