import { GraphQLClient } from 'graphql-request';
import { NetworkName } from 'types/network';
import { Order } from 'types/order';

export type AttributeType =
  | 'Accuracy'
  | 'AcquiredEnergy'
  | 'ArcheryAttack'
  | 'ArcheryDefense'
  | 'ArcheryHP'
  | 'ATK'
  | 'ATKSpeed'
  | 'AwakenI'
  | 'AwakenII'
  | 'AwakenIII'
  | 'AwakeningLevel'
  | 'AwakenIV'
  | 'AwakenV'
  | 'Base'
  | 'birthday'
  | 'CavalryAttack'
  | 'CavalryDefense'
  | 'CavalryHP'
  | 'Class'
  | 'collection'
  | 'ConstructionSpeed'
  | 'cooldown'
  | 'CRTDamage'
  | 'CRTRate'
  | 'CurrentLv'
  | 'drill'
  | 'Evasion'
  | 'extra_color'
  | 'GatheringSpeed'
  | 'Grade'
  | 'HealingSpeed'
  | 'HospitalCapacity'
  | 'HP'
  | 'ID'
  | 'InfantryAttack'
  | 'InfantryDefense'
  | 'InfantryHP'
  | 'lat'
  | 'LocationX'
  | 'LocationY'
  | 'lon'
  | 'machinery'
  | 'MagicDEF'
  | 'MagicPower'
  | 'MarchingTroopCapacity'
  | 'MaximumTroopSizeForRally'
  | 'MaxLv'
  | 'model'
  | 'Name'
  | 'PhysicalDEF'
  | 'primary_color'
  | 'quantity'
  | 'race'
  | 'radar'
  | 'ReceivedRecovery'
  | 'ResearchSpeed'
  | 'ResourceProduction'
  | 'roverPower'
  | 'scarcity'
  | 'serial_number'
  | 'TroopDispatchQueue'
  | 'TroopsSpeed'
  | 'TroopsTrainingCost'
  | 'TroopsTrainingSpeed'
  | 'type';

export type AssetType = {
  animationUri?: string | null;
  owner: string;
  tokenId: string;
  network: NetworkName;
  assetId: number;
  name: string | null;
  imageUri: string | null;
  thumbnailUrl: string | null;
  assetTitle: string | null;
  assetAddress: string | null;
  isInBundle: boolean;
  likeCount: number;
  order: Order;
  attributes: {
    [key in AttributeType]: number | string | null;
  };
  collectionInfo: CollectionType;
};

export type OrderType = {
  auction: AuctionType | null;
  offer: OfferType | null;
  sale: SaleType | null;
  lastSoldInfo: LastSoldType | null;
};

export type SaleType = {
  price: number;
  currency: string;
  createAt: number;
};

export type OfferType = {
  offerTotalCount: number;
  highestOfferPrice: number;
  highestOfferCurrency: string;
  highestOfferCreatedAt: number;
};

export type AuctionType = {
  minBidPrice: number;
  minBidCurrency: string;
  highestBidPrice: number;
  highestBidCurrency: string;
  createdAt: number;
  expireAt: number;
};

export type LastSoldType = {
  price: number;
  currency: string;
  soldAt: string;
};

export type CollectionType = {
  categoryId: number;
  homepage: string;
  imageUrl: string;
  network: string;
  thumbnailUrl: string;
  coverImageUrl: string;
  cardBannerImageUrl: string;
  shortDescription: string;
  title: string;
};

export type AndOperationType = {
  key?: string;
  value?: string;
};

export type OrOperationType = {
  key?: string;
  value?: string;
};

export type GroupByFieldType = {
  [key: string]: {
    [x: string]: any;
    buckets: {
      doc_count: number;
      key: number | string;
    }[];
  };
};

export type RangeType = {
  [key: string]: {
    max: { value: number };
    min: { value: number };
  };
};

export type RangeFilters = {
  key?: string;
  min?: number;
  max?: number;
};

export type BrowseResponse = {
  asset: {
    total: number;
    assets: AssetType[];
    groupByFieldKey: string;
    rangeByFieldKey: string;
  };
};

export enum SortBy {
  Price,
  Popular,
  NewListing,
  Like,
}

const baseURL = process.env.NEXT_PUBLIC_API_HOST || '';
const endpoint = `${baseURL}/graphql`;
const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    // authorization: 'Bearer MY_TOKEN',
  },
});

const membersEndPoint =
  `https://api.thegraph.com/subgraphs/name/supertree-wayne/playdapp_mikey_pfp_${
    process.env.NEXT_PUBLIC_ENV === 'development' ? 'amoy' : 'polygon'
  }2` || '';

export const membersGraphQLClient = new GraphQLClient(membersEndPoint);

export default graphQLClient;
