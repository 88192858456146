import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';

import { RootState } from 'store';
import getAssets from 'api/assets';

import { AssetsResponseData } from 'types/assets';
import { NetworkName } from 'types/network';

type State = {
  filterKeyList: Record<
    NetworkName | 'all' | 'klaytn' | 'solana',
    AssetsResponseData[]
  >;
  isAssetLoading: boolean;
  AssetsError: SerializedError | null;
  query: string;
  currentRequestId?: string;
};

const initialState: State = {
  filterKeyList: {
    all: [],
    polygon: [],
    ethereum: [],
    solana: [],
    klaytn: [],
  },
  isAssetLoading: false,
  AssetsError: null,
  query: '',
  currentRequestId: undefined,
};

export const fetchAssets = createAsyncThunk<
  State['filterKeyList'],
  void,
  { state: RootState }
>('assets/fetchAssets', async (_, { rejectWithValue }) => {
  try {
    const response = await getAssets();

    if (response.status === 200 && response.data) {
      const filterKeyList = response.data.reduce(
        (acc, currentValue) => {
          const { network } = currentValue;
          acc[network] = [...acc[network], currentValue];
          return acc;
        },
        { ...initialState.filterKeyList },
      );

      filterKeyList.ethereum.sort((a, b) => a.categoryId - b.categoryId);
      filterKeyList.polygon.sort((a, b) => a.categoryId - b.categoryId);
      filterKeyList.all = response.data;
      return filterKeyList;
    }
    throw new Error('Failed to get collections info');
  } catch (e) {
    return rejectWithValue(e);
  }
});

const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.pending, (state, action) => {
      state.isAssetLoading = true;
      state.AssetsError = null;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchAssets.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.isAssetLoading = false;
        state.filterKeyList = action.payload;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchAssets.rejected, (state, action) => {
      state.isAssetLoading = false;
      state.AssetsError = action.error;
      state.currentRequestId = undefined;
    });
  },
});

export const { setQuery } = assetSlice.actions;

export default assetSlice.reducer;
