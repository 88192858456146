import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getCollections } from 'api/collections';

import { CollectionResponseData } from 'types/collections';
import { NetworkName } from 'types/network';

type State = {
  collectionList: Record<NetworkName | 'klaytn', CollectionResponseData[]>;
  pureCollectionList: CollectionResponseData[];
  isCollectionsLoading: boolean;
  collectionsError: SerializedError | null;
  query: string;
};

const initialState: State = {
  collectionList: { solana: [], polygon: [], ethereum: [], klaytn: [] },
  pureCollectionList: [],
  isCollectionsLoading: false,
  collectionsError: null,
  query: '',
};

export const fetchCollections = createAsyncThunk<
  Pick<State, 'collectionList' | 'pureCollectionList'>,
  void,
  { state: RootState }
>('collections/fetchCollections', async (_, { rejectWithValue }) => {
  try {
    const response = await getCollections();
    if (response.status === 200 && response.data) {
      return {
        collectionList: response.data.reduce(
          (acc, currentValue) => {
            const { network } = currentValue;

            acc[network] = [...acc[network], currentValue];
            return acc;
          },
          { ...initialState.collectionList },
        ),
        pureCollectionList: response.data,
      };
    }
    throw new Error('Failed to get collections info');
  } catch (e) {
    return rejectWithValue(e);
  }
});

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollections.pending, (state) => {
      state.isCollectionsLoading = true;
      state.collectionsError = null;
    });
    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      state.isCollectionsLoading = false;
      state.collectionList = action.payload.collectionList;
      state.pureCollectionList = action.payload.pureCollectionList;
    });
    builder.addCase(fetchCollections.rejected, (state, action) => {
      state.isCollectionsLoading = false;
      state.collectionsError = action.error;
    });
  },
});

export const { setQuery } = collectionsSlice.actions;

export default collectionsSlice.reducer;
