import { ToastPositionWithLogical, useToast } from '@chakra-ui/react';

import { MessageType } from 'types/event';

import Toast from '@/components/Toast';

type Props = {
  position: ToastPositionWithLogical;
  duration: number | null;
  id?: string;
  isClosable?: boolean;
  containerStyle?: {
    [key: string]: string;
  };
};

const DEFAULT_STYLE = {
  maxWidth: '900px',
  width: 'auto',
  marginTop: '5.5rem',
};

const useToastMessage = ({
  position = 'top',
  duration = 5000,
  id = 'toastMessage',
  containerStyle = DEFAULT_STYLE,
  isClosable = true,
}: Props) => {
  const toast = useToast({
    position,
    duration,
    isClosable,
    containerStyle: { ...DEFAULT_STYLE, ...containerStyle },
  });

  const handleUpdateToast = (
    children: React.ReactNode,
    type: MessageType,
    allowClose: boolean,
  ) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        render: ({ onClose }) => (
          <Toast type={type} allowClose={allowClose} onClose={onClose}>
            {children}
          </Toast>
        ),
      });
    }
  };

  const handleCloseToast = () => {
    toast.close(id);
  };

  return { handleUpdateToast, handleCloseToast, toast };
};

export default useToastMessage;
