import { configureStore } from '@reduxjs/toolkit';
import type { ThunkAction, Action } from '@reduxjs/toolkit';

import wallet from './wallet';
import marketplace from './marketplace';
import marketplaceSolana from './marketplaceSolana';
import user from './user';
import collections from './collections';
import page from './page';
import assets from './assets';
import myAccount from './my-account';
import filters from './filters';
import merge from './merge';
import dropsList from './dropsList';
import filters2 from './filters2';
import advertise from './advertise';
import header from './header';
import connectModal from './connectModal';

export const store = configureStore({
  reducer: {
    wallet,
    marketplace,
    marketplaceSolana,
    user,
    collections,
    page,
    assets,
    myAccount,
    filters,
    merge,
    dropsList,
    filters2,
    advertise,
    header,
    connectModal,
  },
  devTools: process.env.NEXT_PUBLIC_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
