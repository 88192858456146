import { NetworkName, ChainId } from 'types/network';

export const rpcUrls: { [key: number]: string } = {
  1: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_PROJECT_ID}`,
  11155111: `https://sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_PROJECT_ID}`,
  137: 'https://polygon-rpc.com',
  80002: `https://rpc-amoy.polygon.technology`,
  // 1001: 'https://public-node-api.klaytnapi.com/v1/baobab',
  // 8217: 'https://public-node-api.klaytnapi.com/v1/cypress',
  1001: 'https://api.baobab.klaytn.net:8651',
  8217: 'https://public-en-cypress.klaytn.net',
};

type ActiveChainId = {
  ethereum: 1 | 11155111;
  polygon: 137 | 80002;
  solana: 'devnet' | 'mainnet-beta';
  klaytn: 1001 | 8217;
};

export const activeChainId: ActiveChainId = {
  ethereum: process.env.NEXT_PUBLIC_ENV === 'production' ? 1 : 11155111,
  polygon: process.env.NEXT_PUBLIC_ENV === 'production' ? 137 : 80002,
  solana:
    process.env.NEXT_PUBLIC_ENV === 'production' ? 'mainnet-beta' : 'devnet',
  klaytn: process.env.NEXT_PUBLIC_ENV === 'production' ? 8217 : 1001,
};

export const chainIdToNetworkName: { [key: number | string]: string } = {
  1: 'Ethereum',
  11155111: 'Sepolia',
  137: 'Polygon',
  80002: 'Polygon Amoy',
  'mainnet-beta': 'Solana',
  devnet: 'Solana Dev',
  1001: 'Baobab',
  8217: 'Cypress',
};

export const chainIdToMainNetworkName: { [key: number | string]: NetworkName } =
  {
    1: 'ethereum',
    11155111: 'ethereum',
    137: 'polygon',
    80002: 'polygon',
    'mainnet-beta': 'solana',
    devnet: 'solana',
    1001: 'klaytn',
    8217: 'klaytn',
  };

export const networkNameToChainId: { [key: string]: ChainId } = {
  ethereum: process.env.NEXT_PUBLIC_ENV === 'production' ? 1 : 11155111,
  polygon: process.env.NEXT_PUBLIC_ENV === 'production' ? 137 : 80002,
  solana:
    process.env.NEXT_PUBLIC_ENV === 'production' ? 'mainnet-beta' : 'devnet',
  klaytn: process.env.NEXT_PUBLIC_ENV === 'production' ? 8217 : 1001,
};

export const getChainHexCode = (chainId: number): string =>
  `0x${chainId.toString(16)}`;
