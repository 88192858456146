import Slack from 'slack-node';
import { detect } from 'detect-browser';

import { checkMobileDevice, getTimeRemain } from 'lib/util';

type SendSlackMessage = {
  title: string;
  subTitle: string;
  message?: string;
  message2?: string;
  type: 'api' | 'page' | 'sitemap';
};

const slack = new Slack();
const webhookUri =
  'https://hooks.slack.com/services/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';

slack.setWebhook(webhookUri);

const PDMP_S_COUNT = 'PDMP_S_COUNT';
const PDMP_S_DATE = 'PDMP_S_DATE';

const IGNORE_MESSAGES = [
  'Invalid Authorization Token',
  'insertBefore',
  'not exist Drops',
  'supply?seqDrops=13',
  'removeChild',
];

const setSlackCount = (count: number) =>
  sessionStorage.setItem(PDMP_S_COUNT, `${count}`);

const setSlackTime = () =>
  sessionStorage.setItem(PDMP_S_DATE, `${new Date().getTime()}`);

const checkSlackTime = (): boolean => {
  const slackCount = sessionStorage.getItem(PDMP_S_COUNT);
  const slackDate = sessionStorage.getItem(PDMP_S_DATE);

  if (!slackCount || !slackDate) {
    setSlackCount(1);
    return true;
  }

  const { days, hours, minutes, seconds } = getTimeRemain(+slackDate);

  if (
    +slackCount === 1 ||
    (days as number) < -1 ||
    (hours as number) < -1 ||
    (minutes as number) < -1 ||
    (seconds as number) < -59
  ) {
    setSlackCount(+slackCount === 1 ? 2 : 1);
    return true;
  }

  return false;
};

const checkMessage = (message?: string): boolean => {
  if (!message) return true;
  let result = true;

  IGNORE_MESSAGES.forEach((ignoreMessage) => {
    if (result && message.includes(ignoreMessage)) result = false;
  });

  return result;
};

const bundleNotFoundCheck = (
  title: string,
  subTitle: string,
  message2?: string,
) => {
  if (!message2) return true;

  return (
    !title.includes('Backend API Error') &&
    !subTitle.includes('GET') &&
    !subTitle.includes('bundle') &&
    message2 !== '404 - Not found'
  );
};

const sendSlackMessage = ({
  title,
  subTitle,
  message,
  message2,
  type,
}: SendSlackMessage) => {
  if (
    checkMessage(subTitle) &&
    checkMessage(message) &&
    checkMessage(message2) &&
    bundleNotFoundCheck(title, subTitle, message2) &&
    checkSlackTime()
  ) {
    const browser = detect();

    let blocks = [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `${subTitle}${
            type === 'api' &&
            subTitle &&
            window?.location?.pathname &&
            `\nPage: ${window.location.pathname}`
          }`,
        },
      },
    ];

    if (message) {
      blocks = blocks.concat({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*${message}*`,
        },
      });
    }

    if (message2) {
      blocks = blocks.concat({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*${message2}*`,
        },
      });
    }

    if (browser) {
      const { name, os, type } = browser;

      blocks = blocks.concat({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `접속 환경: ${
            checkMobileDevice() ? 'Mobile' : 'PC'
          } / ${os} / ${name} / ${type}`,
        },
      });
    }

    blocks = blocks.concat({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: '<https://sentry.io/organizations/supertree-9y/issues/?environment=development&project=6364859&query=is%3Aunresolved&referrer=sidebar|Go to Sentry: marketplace-fe-v2>',
      },
    });

    slack.webhook(
      {
        text: `*${title}*`,
        attachments: [
          {
            blocks,
          },
        ],
      },
      () => {},
    );

    setSlackTime();
  }
};

export default sendSlackMessage;
