import { Web3Provider } from '@ethersproject/providers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Connection, PublicKey } from '@solana/web3.js';
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
} from '@solana/wallet-adapter-wallets';

import getKlayBalance from 'lib/caver/getBalance';
import getBalance, { getTokenBalance } from 'lib/web3/getBalance';

import type { WalletProvider } from 'types/wallet';
import type { Klay } from 'caver-js';

import { tokenAddress } from 'data/crypto-token';

import { RootState } from 'store';

type Balances = {
  [key in string]: number;
};

type State = {
  account: string | null;
  provider: WalletProvider | null;
  activeChainId: number | null;
  balance: Balances | null;
  expireDate: string | null;
  library: Web3Provider | null;
  currentRequestId?: string;
  isBalanceLoading: boolean;
  isBalanceError: boolean;
  isLoading: boolean;
  connection: Connection | null;
  publicKey: PublicKey | null;
  chainId: number | string | null;
  solanaWalletAdapter: GlowWalletAdapter | PhantomWalletAdapter | null;
};

type GetBalancesParams = {
  account: string;
  library: Web3Provider;
  chainId: number;
};

type GetKlayBalanceParams = { lib: Klay; account: string };

const initialState: State = {
  account: null,
  provider: null,
  activeChainId: null,
  balance: null,
  expireDate: null,
  library: null,
  currentRequestId: undefined,
  isBalanceLoading: true,
  isBalanceError: false,
  isLoading: false,
  connection: null,
  publicKey: null,
  chainId: null,
  solanaWalletAdapter: null,
};

export const getKlaytnBalances = createAsyncThunk<
  Balances,
  GetKlayBalanceParams,
  { state: RootState }
>(
  'wallet/getKlaytnBalances',
  async ({ lib, account }: GetKlayBalanceParams) => {
    const balance = await getKlayBalance(lib, account);
    return { klay: balance };
  },
);

export const getBalances = createAsyncThunk<
  Balances,
  GetBalancesParams,
  { state: RootState }
>(
  'wallet/getTokenBalance',
  async ({ account, library, chainId }: GetBalancesParams) => {
    const nativeCurrencyName = {
      1: 'eth',
      11155111: 'eth',
      137: 'matic',
      80002: 'matic',
      1001: 'klay',
      8217: 'klay',
    };

    const balances: Balances = {
      pla: -1,
      eth: -1,
      matic: -1,
      weth: -1,
      klay: -1,
      wklay: -1,
    };

    balances[
      nativeCurrencyName[chainId as 1 | 137 | 1001 | 8217 | 80002 | 11155111]
    ] = await getBalance(account, library);

    const tokenList = Object.keys(tokenAddress) as Array<
      keyof typeof tokenAddress
    >;

    const requestTokenList = tokenList.map((token) =>
      (async () => await getTokenBalance(account, token, chainId, library))(),
    );

    const resBalanceList = await Promise.all(requestTokenList);

    resBalanceList.forEach((balance, index) => {
      balances[tokenList[index]] = balance;
    });

    Object.keys(balances).forEach((balance) => {
      if (
        Object.prototype.hasOwnProperty.call(balances, balance) &&
        balances[balance] === -1
      ) {
        delete balances[balance];
      }
    });

    return balances;
  },
);

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletInfo: (state, action) => {
      const {
        account,
        activeChainId,
        provider,
        library,
        expireDate,
        connection,
        publicKey,
        chainId,
        solanaWalletAdapter,
      } = action.payload;

      state.isLoading = false;
      state.account = account;
      state.provider = provider;
      state.expireDate = expireDate;
      state.chainId = chainId;

      if (activeChainId) state.activeChainId = activeChainId;
      if (library) state.library = library;
      if (publicKey) state.publicKey = publicKey;
      if (connection) state.connection = connection;
      if (solanaWalletAdapter) state.solanaWalletAdapter = solanaWalletAdapter;
    },
    setWalletLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setBalanceLoading: (state, action) => {
      state.isBalanceLoading = action.payload;
    },
    setBalanceError: (state, action) => {
      state.isBalanceError = action.payload;
    },
    setBalance: (state, action) => {
      state.isBalanceLoading = false;
      state.balance = action.payload;
    },
    setChainId: (state, action) => {
      state.chainId = action.payload;
    },
    resetWalletInfo: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getBalances.pending, (state, action) => {
      state.isBalanceLoading = true;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(getBalances.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.isBalanceLoading = false;
        state.balance = action.payload;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(getKlaytnBalances.pending, (state, action) => {
      state.isBalanceLoading = true;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(getKlaytnBalances.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.isBalanceLoading = false;
        state.balance = action.payload;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const {
  setWalletInfo,
  setWalletLoading,
  setBalanceLoading,
  setBalanceError,
  setBalance,
  setChainId,
  resetWalletInfo,
} = walletSlice.actions;

export default walletSlice.reducer;
